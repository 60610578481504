@charset "UTF-8";
*{	margin: 0;	padding: 0;	list-style: none;text-decoration: none;	box-sizing: border-box; position: relative; -webkit-box-sizing: border-box; }
:root {  --hdr: 80px; } 
*:focus {  outline: none; }
/*****Clases de loader prinicpal **/
	.loader{
	  background: #000;width: 100%;height: 100vh;display: flex;justify-content: center;
	  align-items: center;position: fixed;top: 0; left: 0;z-index: 9000; clip-path: circle(150% at 100% 0);
	   transition: clip-path 0.8s ease-in-out;}
	.loader2{clip-path:circle(0% at  100%  100%) ;}

	@keyframes spin3D {
	  from {    transform: rotate3d(.5,.5,.5, 360deg);  }
	  to{    transform: rotate3d(0deg);  }
	}

body{	overflow-x: hidden; margin:0 auto;font-size:16px ; 
  background-image: url("../../public/img/iconos/fondo_bulletant.png");
  font-family: 'Raleway', sans-serif;background-position: center;  
  background-repeat: repeat; background-size: cover; width: 100%;  margin: 0;  padding: 0;
  -webkit-font-smoothing: antialiased;  -moz-osx-font-smoothing: grayscale; }

html {  line-height: 1.15;  -ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%; 
     -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;  
    width: 100%;height: 100%;font-family: 'Source Code Pro'; font-weight: 400;
    color: #333333;  font-size: 62.5%;  line-height: 1;  -ms-touch-action: auto;
    touch-action: auto; }
article,aside,footer,header,nav,section {  display: block; }
h4,h2,h1,ul,h1, h3, h4, h5, h6,ul ,p{ padding: 0;margin:0;list-style: none;}
h1 {  font-size: 2em;  margin: 0.67em 0; }
ul li,ol li {  list-style: none; }
figure {  -webkit-margin-before: 0;  -webkit-margin-after: 0;
  -webkit-margin-start: 0;  -webkit-margin-end: 0;  margin: 0; }
figcaption,figure,main {  display: block; }
hr {  -webkit-box-sizing: content-box;  box-sizing: content-box;  height: 0;
  overflow: visible; }
pre {  font-family: monospace, monospace;  font-size: 1em; }
a {  background-color: transparent; -webkit-text-decoration-skip: objects;
  text-decoration: none !important; color: inherit;cursor: pointer;outline: none; }
   
 
abbr[title] {  border-bottom: none;  text-decoration: underline;
  -webkit-text-decoration: underline dotted;text-decoration: underline dotted; }
b,strong {  font-weight: inherit; }
b,strong {  font-weight: bolder; }
code,kbd,samp {font-family: monospace, monospace; font-size: 1em; }
dfn {  font-style: italic; }
mark {  background-color: #ff0;  color: #000; }
small {  font-size: 80%; }
sub,sup {  font-size: 75%;  line-height: 0;
  position: relative;  vertical-align: baseline; }
sub {  bottom: -0.25em; }
sup {  top: -0.5em; }
audio,video {  display: inline-block; }
audio:not([controls]) {  display: none;  height: 0; }
svg:not(:root) {  overflow: hidden; }
input,optgroup,select,textarea {  font-family: sans-serif;
  font-size: 100%;  line-height: 1.15;  margin: 0; }
input {  overflow: visible; }
select {  text-transform: none; }
fieldset {  padding: 0.35em 0.75em 0.625em; }
legend {  -webkit-box-sizing: border-box;  box-sizing: border-box;
  color: inherit;  display: table;  max-width: 100%;
  padding: 0;  white-space: normal; }
progress {  display: inline-block;  vertical-align: baseline; }
textarea {  overflow: auto; }
[type="checkbox"],[type="radio"] {  -webkit-box-sizing: border-box;
  box-sizing: border-box;  padding: 0; }
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {  height: auto; }
[type="search"] { -webkit-appearance: textfield;  outline-offset: -2px; }
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {  -webkit-appearance: none; }
::-webkit-file-upload-button {  -webkit-appearance: button; font: inherit; }
details,menu {  display: block; }
summary {  display: list-item; }
canvas {  display: inline-block; }
template {  display: none; }
[hidden] {  display: none; }




/**** SLIDER BULLENT-ANT *******/
  .slick-slider {  position: relative;  display: block;
    -webkit-box-sizing: border-box;  box-sizing: border-box;
    -webkit-user-select: none;  -moz-user-select: none;  -ms-user-select: none;
    user-select: none;  -webkit-touch-callout: none;  -khtml-user-select: none;
    -ms-touch-action: pan-y;  touch-action: pan-y;  -webkit-tap-highlight-color: transparent; }
  .slick-list { position: relative;display: block;overflow: hidden;  margin: 0;  padding: 0; }
  .slick-list:focus {  outline: none; }
  .slick-list.dragging { cursor: pointer;  cursor: hand; }
  .slick-slider .slick-track,.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .slick-track {  position: relative;top: 0;left: 0;display: block; }
  .slick-track:before,.slick-track:after { display: table; content: ''; }
  .slick-track:after {  clear: both; }
  .slick-loading .slick-track {  visibility: hidden; }
  .slick-slide {display: none; float: left;  height: 100%;  min-height: 1px; }
  [dir='rtl'] .slick-slide {  float: right; }
  .slick-slide img {  display: block; }
  .slick-slide.slick-loading img {  display: none; }
  .slick-slide.dragging img {  pointer-events: none; }
  .slick-initialized .slick-slide {  display: block; }
  .slick-loading .slick-slide {  visibility: hidden; }
  .slick-vertical .slick-slide {  display: block; height: auto;
    border: 1px solid transparent; }
  .slick-arrow.slick-hidden {  display: none; }

  .wrap { width: 100%;  overflow: hidden; }
  .inner { height: 100%;  display: -webkit-box;
    display: -webkit-flex;  display: -ms-flexbox;  display: flex; }
  .flex {  display: -webkit-box;  display: -webkit-flex;
    display: -ms-flexbox;  display: flex; }
  .slick-list,.slick-track {  height: 100%;  width: 100%; }
  .main .first-screen {  height: 100vh; }
  .main .title {  width: calc(100vh - 2rem - var(--hdr));
    -webkit-transform: rotate(-90deg) translate(calc(-100vh + 2rem), 0);
    -ms-transform: rotate(-90deg) translate(calc(-100vh + 2rem), 0);
    transform: rotate(-90deg) translate(calc(-100vh + 2rem), 0); }
  .main .sidebar__title_hero {
    -webkit-transform: rotate(-90deg) translate(-90vh, calc(190px - 100%));
    -ms-transform: rotate(-90deg) translate(-90vh, calc(190px - 100%));
    transform: rotate(-90deg) translate(-90vh, calc(190px - 100%)); }
  .main .title_inner {  position: relative;  height: auto;  width: auto;
    -webkit-transform: rotate(-90deg) translate(calc(-100% - 2rem));
    -ms-transform: rotate(-90deg) translate(calc(-100% - 2rem));
    transform: rotate(-90deg) translate(calc(-100% - 2rem));
    font-size: 4rem; }
  .first-screen {  background: #435d3a;  height: calc(200vh/3);  width: 100%; }
  .content_l {  width: 90%;  margin-right: auto; }
  .content_r {  width: 90%;  margin-left: auto; }
  .content_c {  width: 80%;  margin: 0 auto; }
  .content {  width: 100%; }
  .sidebar {  width: 190px;  min-height: calc(200vh/3);
    -webkit-box-flex: 0;  -webkit-flex: none;  -ms-flex: none;  flex: none; }
  .sidebar h2{text-shadow: 2px 2px 2px #460B09;}
  .title {  width: calc(200vh/3 - 2rem - var(--hdr));
    font-size: 4rem;  font-weight: 400;  color: #fff;
    text-transform: uppercase;  -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;  transform-origin: 0 0;
    -webkit-transform: rotate(-90deg) translate(calc(-200vh/3 + 2rem), 0);
    -ms-transform: rotate(-90deg) translate(calc(-200vh/3 + 2rem), 0);
    transform: rotate(-90deg) translate(calc(-200vh/3 + 2rem), 0);
    display: inline-block;  left: 0;  top: 0;  margin: 0; }
  .sidebar_inner {  width: 40px;  height: auto;  min-height: auto; }
  .title_inner {  position: relative;  height: auto;  width: auto;
    -webkit-transform: rotate(-90deg) translate(calc(-100% - 2rem));
    -ms-transform: rotate(-90deg) translate(calc(-100% - 2rem));
    transform: rotate(-90deg) translate(calc(-100% - 2rem));
    font-size: 4rem; }
  .scrolled {  height: 100%;  overflow: auto;  overflow-y: scroll; }
  .scrolled::-webkit-scrollbar {    width: 6px; }
  .scrolled::-webkit-scrollbar-track {    background: transparent; }
  .scrolled::-webkit-scrollbar-thumb { background: #66cccc; border-radius: 3px; }
  .one-third {  width: calc(100%/3 - 30px); }
  .two-thirds {  -webkit-box-flex: 1;  -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;  flex: 1 0 0;  margin-left: 30px; }
  .breadcrumbs {  width: 100%; margin-top: auto;display: -webkit-box;
    display: -webkit-flex;display: -ms-flexbox; display: flex;
    -webkit-box-orient: horizontal; -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;  -ms-flex-flow: row wrap;flex-flow: row wrap; }
  .breadcrumbs__back {  margin-bottom: 1rem; }
  .breadcrumbs__back.constructor {  margin-top:  1rem; margin-bottom: 0; }
  .back { font-size: 2rem;color: #fff;  letter-spacing: .075em;text-transform: uppercase; }
  .back::before {content: "";position: absolute; height: 1px; width: 0; left: 0;
      bottom: -.25rem; background-color: #fff;-webkit-transition: all 0.2s ease-in-out 0s;
      -o-transition: all 0.2s ease-in-out 0s; transition: all 0.2s ease-in-out 0s; }
  .back:hover::before { width: 100%; }
  .breadcrumbs__lst {  width: 100%; display: inline-block; }
  .breadcrumbs__itm { display: inline-block; margin-left: .75em;padding: .5rem 0; }
  .breadcrumbs__itm:first-child {   margin-left: 0; }
  .breadcrumbs__itm:last-child .icon_breadcrumbs { display: none; }
  .breadcrumbs__itm:last-child .breadcrumbs__txt { font-weight: 700; }
  .breadcrumbs__itm:hover .breadcrumbs__txt::before { width: 100%; }
  .breadcrumbs__lnk { display: -webkit-box; display: -webkit-flex;
    display: -ms-flexbox;display: flex;  -webkit-box-align: center;
    -webkit-align-items: center;-ms-flex-align: center;  align-items: center; }
  .breadcrumbs__txt {  font-size: 2rem; color: #fff;  letter-spacing: .075em;
    text-transform: uppercase; }
  .breadcrumbs__txt::before {  content: ""; position: absolute;
    height: 1px;width: 0; left: 0;bottom: -.25rem;background-color: #fff;
    -webkit-transition: all 0.2s ease-in-out 0s; -o-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s; }
  .icon_breadcrumbs {  width: 35px;  height: 15px;fill: #fff; margin-left: 1.5rem; }
  .pdg { padding-top: calc(var(--hdr) + 2rem); }
  .btn { min-height: 40px;  padding: .5rem 20px;font-size: 2rem;  color: #fff;  text-transform: uppercase;
    background: #0f949b; display: -webkit-inline-box;  display: -webkit-inline-flex;
    display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -webkit-align-items: center;
    -ms-flex-align: center;  align-items: center; }
  .btn:hover, .btn:active, .btn:focus {   background-color: #003333; }
  .wrap_popup {  height: calc(100vh - var(--hdr)); }
  .wrap_popup::after { content: ''; width: 100%; height: 33%; position: absolute; bottom: 0; left: 0;
      background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#435d3a));
      background: -webkit-linear-gradient(top, transparent, #435d3a);
      background: -o-linear-gradient(top, transparent, #435d3a);
      background: linear-gradient(to bottom, transparent, #435d3a); z-index: -1;mix-blend-mode: multiply; }
  .icon_close {  width: 50px;  height: 50px;
    fill: #fff;  padding: 15px;  right: calc( -50px/2 + .5rem);
    top: 1rem;  position: absolute;  z-index: 5; cursor: pointer; }
  .up {  cursor: pointer;  position: fixed;  bottom: 5rem;right: 2.5rem;
    color: #0f949b;display: -webkit-inline-box;display: -webkit-inline-flex;
    display: -ms-inline-flexbox;display: inline-flex;-webkit-box-align: end;
    -webkit-align-items: flex-end;-ms-flex-align: end;align-items: flex-end;
    z-index: 3; max-width: 90px;max-height: 40px;width: 100%; height: 100%;  display: none; }
  .up:hover .icon_up, .up:focus .icon_up {  -webkit-animation-name: wobbleUp;
      animation-name: wobbleUp; -webkit-animation-duration: 1s; animation-duration: 1s;
      -webkit-animation-timing-function: ease-in-out; animation-timing-function: ease-in-out;
      -webkit-animation-iteration-count: 1; animation-iteration-count: 1; }
  .up:active .icon_up { -webkit-transform: rotate(-90deg) translate(2px, 2px);
      -ms-transform: rotate(-90deg) translate(2px, 2px); transform: rotate(-90deg) translate(2px, 2px); }
  .up__txt { font-size: 16px; margin-right: -.5rem; }
  .up__arrow { height: 40px; width: auto; }
  .icon_up { -webkit-transform: rotate(-90deg); -ms-transform: rotate(-90deg);
    transform: rotate(-90deg); width: 40px; height: 40px; fill: currentColor; }
  .down { display: none; cursor: pointer;  position: fixed;  top: calc(var(--hdr) + 5rem);
    right: 2.5rem;  color: #0f949b;display: -webkit-inline-box; display: -webkit-inline-flex;
    display: -ms-inline-flexbox;  display: inline-flex; -webkit-box-align: end;
    -webkit-align-items: flex-end; -ms-flex-align: end; align-items: flex-end;
    z-index: 3;  max-width: 90px;  max-height: 40px;  width: 100%;  height: 100%; }
  .down:hover .icon_up, .down:focus .icon_up { -webkit-animation-name: wobbleDown;
      animation-name: wobbleDown; -webkit-animation-duration: 1s;    animation-duration: 1s;
      -webkit-animation-timing-function: ease-in-out;    animation-timing-function: ease-in-out;
      -webkit-animation-iteration-count: 1;    animation-iteration-count: 1; }
  .down:active .icon_up {    -webkit-transform: rotate(90deg) translate(2px, 2px);
      -ms-transform: rotate(90deg) translate(2px, 2px);  transform: rotate(90deg) translate(2px, 2px); }
  .down .icon_up { -webkit-transform: rotate(90deg); -ms-transform: rotate(90deg);transform: rotate(90deg); }

/*** Wobble Horizontal**** */
  @-webkit-keyframes wobble {
    16.65% {    -webkit-transform: translateX(8px);    transform: translateX(8px); }
    33.3% {    -webkit-transform: translateX(-6px);    transform: translateX(-6px); }
    49.95% {    -webkit-transform: translateX(4px);    transform: translateX(4px); }
    66.6% {    -webkit-transform: translateX(-2px);    transform: translateX(-2px); }
    83.25% {    -webkit-transform: translateX(1px);    transform: translateX(1px); }
    100% {    -webkit-transform: translateX(0);    transform: translateX(0); } }
  @keyframes wobble {
    16.65% {    -webkit-transform: translateX(8px);    transform: translateX(8px); }
    33.3% {    -webkit-transform: translateX(-6px);    transform: translateX(-6px); }
    49.95% {    -webkit-transform: translateX(4px);    transform: translateX(4px); }
    66.6% {    -webkit-transform: translateX(-2px);    transform: translateX(-2px); }
    83.25% {    -webkit-transform: translateX(1px);    transform: translateX(1px); }
    100% {    -webkit-transform: translateX(0);    transform: translateX(0); } }
  @-webkit-keyframes wobbleUp {
    16.65% { -webkit-transform: translateY(8px) rotate(-90deg); transform: translateY(8px) rotate(-90deg); }
    33.3% {  -webkit-transform: translateY(-6px) rotate(-90deg);transform: translateY(-6px) rotate(-90deg); }
    49.95% { -webkit-transform: translateY(4px) rotate(-90deg); transform: translateY(4px) rotate(-90deg); }
    66.6% {  -webkit-transform: translateY(-2px) rotate(-90deg);transform: translateY(-2px) rotate(-90deg); }
    83.25% { -webkit-transform: translateY(1px) rotate(-90deg);transform: translateY(1px) rotate(-90deg); }
    100% { -webkit-transform: translateY(0) rotate(-90deg); transform: translateY(0) rotate(-90deg); } }

  @keyframes wobbleUp {
    16.65% { -webkit-transform: translateY(8px) rotate(-90deg); transform: translateY(8px) rotate(-90deg); }
    33.3% { -webkit-transform: translateY(-6px) rotate(-90deg); transform: translateY(-6px) rotate(-90deg); }
    49.95% { -webkit-transform: translateY(4px) rotate(-90deg); transform: translateY(4px) rotate(-90deg); }
    66.6% {-webkit-transform: translateY(-2px) rotate(-90deg); transform: translateY(-2px) rotate(-90deg); }
    83.25% { -webkit-transform: translateY(1px) rotate(-90deg); transform: translateY(1px) rotate(-90deg); }
    100% {  -webkit-transform: translateY(0) rotate(-90deg); transform: translateY(0) rotate(-90deg); } }

  @-webkit-keyframes wobbleDown {
    16.65% {-webkit-transform: translateY(8px) rotate(90deg); transform: translateY(8px) rotate(90deg); }
    33.3% {-webkit-transform: translateY(-6px) rotate(90deg); transform: translateY(-6px) rotate(90deg); }
    49.95% { -webkit-transform: translateY(4px) rotate(90deg); transform: translateY(4px) rotate(90deg); }
    66.6% { -webkit-transform: translateY(-2px) rotate(90deg); transform: translateY(-2px) rotate(90deg); }
    83.25% {-webkit-transform: translateY(1px) rotate(90deg);  transform: translateY(1px) rotate(90deg); }
    100% { -webkit-transform: translateY(0) rotate(90deg); transform: translateY(0) rotate(90deg); } }

  @keyframes wobbleDown {
    16.65% { -webkit-transform: translateY(8px) rotate(90deg); transform: translateY(8px) rotate(90deg); }
    33.3% { -webkit-transform: translateY(-6px) rotate(90deg); transform: translateY(-6px) rotate(90deg); }
    49.95% {-webkit-transform: translateY(4px) rotate(90deg); transform: translateY(4px) rotate(90deg); }
    66.6% { -webkit-transform: translateY(-2px) rotate(90deg);transform: translateY(-2px) rotate(90deg); }
    83.25% {-webkit-transform: translateY(1px) rotate(90deg); transform: translateY(1px) rotate(90deg); }
    100% { -webkit-transform: translateY(0) rotate(90deg); transform: translateY(0) rotate(90deg); } }
  @-webkit-keyframes fadeIn {  from {    opacity: 0; }    to {    opacity: 1; } }
  @keyframes fadeIn {  from {    opacity: 0; }  to {    opacity: 1; } }
  .fadeIn { -webkit-animation-name: fadeIn;  animation-name: fadeIn; }

  @-webkit-keyframes fadeInDown {
    from {    opacity: 0;    -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0); }
    to {    opacity: 1;    -webkit-transform: none;    transform: none; } }
  @keyframes fadeInDown {
    from {    opacity: 0;    -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0); }
    to {    opacity: 1;    -webkit-transform: none;    transform: none; } }
  .fadeInDown { -webkit-animation-name: fadeInDown; animation-name: fadeInDown; }
  @-webkit-keyframes fadeInLeft {
    from {    opacity: 0;  -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0); }
    to {    opacity: 1;    -webkit-transform: none;    transform: none; } }
  @keyframes fadeInLeft {
    from { opacity: 0; -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0); }
    to { opacity: 1; -webkit-transform: none;   transform: none; } }
  .fadeInLeft { -webkit-animation-name: fadeInLeft;animation-name: fadeInLeft; }
  @-webkit-keyframes fadeInRight {
    from {    opacity: 0;    -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0); }
    to {    opacity: 1;    -webkit-transform: none;    transform: none; } }

  @keyframes fadeInRight {
    from {    opacity: 0;   -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0); }
    to {    opacity: 1;    -webkit-transform: none;    transform: none; } }
  .fadeInRight { -webkit-animation-name: fadeInRight;  animation-name: fadeInRight; }
  @-webkit-keyframes fadeInUp {
    from {    opacity: 0;   -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0); }
    to {    opacity: 1;    -webkit-transform: none;    transform: none; } }

/*** key frame *************/
  @keyframes fadeInUp {
    from {    opacity: 0;    -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0); }
    to {    opacity: 1;    -webkit-transform: none;
      transform: none; } }
  .fadeInUp {  -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp; }
  @-webkit-keyframes fadeOut {  from {    opacity: 1; }  to {    opacity: 0; } }
  @keyframes fadeOut {  from {    opacity: 1; }  to {    opacity: 0; } }
  .fadeOut {  -webkit-animation-name: fadeOut;  animation-name: fadeOut; }
  @-webkit-keyframes fadeOutDown {
    from {    opacity: 1; }
    to {    opacity: 0; -webkit-transform: translate3d(0, 100%, 0);    transform: translate3d(0, 100%, 0); } }
  @keyframes fadeOutDown {
    from {    opacity: 1; }
    to {    opacity: 0; -webkit-transform: translate3d(0, 100%, 0); transform: translate3d(0, 100%, 0); } }
  .fadeOutDown { -webkit-animation-name: fadeOutDown;  animation-name: fadeOutDown; }
  @-webkit-keyframes fadeOutDownBig {
    from {    opacity: 1; }  to {    opacity: 0;    -webkit-transform: translate3d(0, 2000px, 0);
      transform: translate3d(0, 2000px, 0); } }
  @keyframes fadeOutDownBig {
    from {    opacity: 1; }  to {    opacity: 0;    -webkit-transform: translate3d(0, 2000px, 0);
      transform: translate3d(0, 2000px, 0); } }
  .fadeOutDownBig {  -webkit-animation-name: fadeOutDownBig; animation-name: fadeOutDownBig; }
  @-webkit-keyframes fadeOutLeft {
    from {    opacity: 1; }  to {    opacity: 0;    -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0); } }
  @keyframes fadeOutLeft {
    from {    opacity: 1; }  to {    opacity: 0;    -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0); } }
  .fadeOutLeft {  -webkit-animation-name: fadeOutLeft;  animation-name: fadeOutLeft; }
  @-webkit-keyframes fadeOutLeftBig {
    from {    opacity: 1; }
    to {    opacity: 0;    -webkit-transform: translate3d(-2000px, 0, 0);
      transform: translate3d(-2000px, 0, 0); } }
  @keyframes fadeOutLeftBig {
    from {    opacity: 1; }
    to {    opacity: 0;    -webkit-transform: translate3d(-2000px, 0, 0);
      transform: translate3d(-2000px, 0, 0); } }

  .fadeOutLeftBig {  -webkit-animation-name: fadeOutLeftBig;  animation-name: fadeOutLeftBig; }
  @-webkit-keyframes fadeOutRight {  from {    opacity: 1; }
    to {    opacity: 0;    -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0); } }
  @keyframes fadeOutRight {
    from {    opacity: 1; }  to {    opacity: 0;    -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0); } }
  .fadeOutRight {  -webkit-animation-name: fadeOutRight;  animation-name: fadeOutRight; }
  @-webkit-keyframes fadeOutUp {
    from {    opacity: 1; }  to {    opacity: 0;    -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0); } }
  @keyframes fadeOutUp {
    from {    opacity: 1; }  to {    opacity: 0;    -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0); } }

.fadeOutUp {  -webkit-animation-name: fadeOutUp;  animation-name: fadeOutUp; }
.slideshow-360 {  display: none; }
.hero {  display: block; }
.hero__lst {  width: 100%;  height: 100vh;  display: inline-block; }
.hero__itm {  width: 100%;  height: 100%;  display: -webkit-box;
  display: -webkit-flex;  display: -ms-flexbox;  display: flex; }
.sidebar_hero {  position: absolute;  z-index: 1;  width: calc(190px + 10%);
  height: 100%;  background:rgba(67,93,58, 0.6); }
.sidebar__title_hero {
  -webkit-transform: rotate(-90deg) translate(calc(-100vh + 2rem), calc(190px - 100%));
  -ms-transform: rotate(-90deg) translate(calc(-100vh + 2rem), calc(190px - 100%));
  transform: rotate(-90deg) translate(calc(-100vh + 2rem), calc(190px - 100%)); }

.hero__itm-inner {  width: 100%;  height: 100%;  display: -webkit-box;
  display: -webkit-flex;  display: -ms-flexbox;  display: flex;
  -webkit-box-align: center;  -webkit-align-items: center;  -ms-flex-align: center;
  align-items: center;  -webkit-box-pack: center;  -webkit-justify-content: center;
  -ms-flex-pack: center;  justify-content: center; }
.hero__pic {  width: 100%;  height: 100%;  position: absolute; top: 0; left: 0; }
.hero__pic::after { content: ''; width: 100%;  height: 100%;
    position: absolute;   bottom: 0;    left: 0;
    background: rgba(51, 51, 51, 0.5); }
.hero__pic::before {    content: '';   width: 100%;   height: 100%;    position: absolute;    top: 0;    left: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(#003333), color-stop(33.3%, transparent), color-stop(66.6%, transparent), to(#003333));
    background: -webkit-linear-gradient(top, #003333 0%, transparent 33.3%, transparent 66.6%, #003333 100%);
    background: -o-linear-gradient(top, #003333 0%, transparent 33.3%, transparent 66.6%, #003333 100%);
    background: linear-gradient(to bottom, #003333 0%, transparent 33.3%, transparent 66.6%, #003333 100%);
    z-index: 1;
    mix-blend-mode: multiply; }
.hero__img {  width: 100%;  height: 100%;  -o-object-fit: cover;  object-fit: cover; }
.hero__itm-info {  width: 50%;  margin-left: 25%; }
.hero__title {  padding: 1.5rem 2rem;  display: -webkit-inline-box;
  display: -webkit-inline-flex;  display: -ms-inline-flexbox;  display: inline-flex;
  -webkit-box-orient: vertical;  -webkit-box-direction: normal;  -webkit-flex-direction: column;
  -ms-flex-direction: column;  flex-direction: column; }
.hero__title::after {    content: '';    width: 100%;height: 100%;  position: absolute;  top: 0;
    left: 0;    z-index: 0;    background: rgba(67,93,58,0.6)  ;  mix-blend-mode: multiply; }
.hero__rostec {  padding: 2.25rem 4rem 1.75rem; }
.hero__rostec::after {    width: 95%; }
.logo__r {  width: 115px;  fill: #fff;  z-index: 1; }
.hero__title-lnk {  color: #fff;  z-index: 1; }
.hero__title-lnk::before {    content: "";  position: absolute; height: 1px; width: 100%;
    left: 0; bottom: -.25rem;   background-color: #fff;   -webkit-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;  transition: all 0.2s ease-in-out 0s; }
.hero__title-lnk:hover::before {    width: 0; }
.hero__title-line {  margin: .5rem 0;  z-index: 1; }
.hero__title-txt {  margin: 0;  font-size: 4rem;  color: #fff;  text-transform: uppercase;
  letter-spacing: .075em;  font-weight: 400; }
.hero__title-addon {  width: 70%;  margin-left: auto;font-size: 1.8rem;  color: #fff;
  text-align: justify;  margin-top: 2rem;  letter-spacing: .075em;
  -webkit-hyphens: auto;  -ms-hyphens: auto;  hyphens: auto; }
.hero__dots-lst {  position: absolute;  bottom: 2.5rem;  left: 10%; }
.s-category .content {  width: calc(100% - 190px); }
.category__lst {  width: 100%;  display: -webkit-box;  display: -webkit-flex;
  display: -ms-flexbox;  display: flex;  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;  flex-flow: row nowrap;  overflow: scroll;  height: 100vh; }
 .category__lst:hover .category__itm:hover {      opacity: .5; }
.category__itm {  width: calc(100%/6);  height: 100%;-webkit-transition: all 0.3s ease; 
 -o-transition: all 0.3s ease;  transition: all 0.3s ease; }
.category__itm-inner {width: 100%; height: 100%;display: -webkit-box;
  display: -webkit-flex;  display: -ms-flexbox;  display: flex; }
.category__pic {  width: 100%;  height: 100%;  position: absolute;
  top: 0;  left: 0; }
.category__pic::after {    content: '';    width: 100%; height: 100%;  position: absolute;
    top: 0;    left: 0;  background: -webkit-gradient(linear, left top, left bottom, from(#435d3a), to(transparent)), -webkit-gradient(linear, left top, left bottom, from(rgba(67,93,58,0.6)), to(transparent));
    background: -webkit-linear-gradient(top, #435d3a, transparent), -webkit-linear-gradient(top, rgba(67,93,58,0.6) , transparent);
    background: -o-linear-gradient(top, #435d3a, transparent), -o-linear-gradient(top, rgba(67,93,58,0.6), transparent);
    background: linear-gradient(to bottom, #435d3a, transparent), linear-gradient(to bottom, rgba(67,93,58,0.6), transparent);
    mix-blend-mode: multiply; }
.category__img {  width: 100%;  height: 100%;  -o-object-fit: cover;  object-fit: cover;
  -o-object-position: center center;  object-position: center center; } 
.category__info {  padding: 2rem;  margin-top: calc(var(--hdr) + 7rem);
  display: -webkit-inline-box;  display: -webkit-inline-flex;  display: -ms-inline-flexbox;
  display: inline-flex;  -webkit-box-pack: justify;  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;  justify-content: space-between;  width: 100%; }
.category__name {  font-size: 1.5rem;  font-weight: 700;  color: #fff;  line-height: 1.2;
  text-transform: uppercase;  -webkit-hyphens: auto;  -ms-hyphens: auto;  hyphens: auto;
  cursor: pointer;  width: 70%;  margin: 0;  display: inline-table; }
.icon_next {  width: 35px;  height: 15px;  fill: #fff; }
.icon_category {  opacity: 0; }
.category__datas {  display: none; }
.mpage-about {  height: 100vh; }
.mpage-about .title_inner {    white-space: nowrap; }
.content_about {  display: -webkit-box;  display: -webkit-flex;  display: -ms-flexbox;
  display: flex;  -webkit-box-orient: horizontal;  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;  -ms-flex-flow: row wrap;  flex-flow: row wrap;
  -webkit-box-pack: justify;  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;  justify-content: space-between;  padding-top: var(--hdr);  height: 100%; }
.wrap_slider {  width: 40%;  height: 100%; }
.slider-about__lst {  width: 100%;  height: 100%;  display: block; }
.slider-about__lst .slick-slider,  .slider-about__lst .slick-list .slick-track,
.slider-about__lst .slick-slide {    height: 100%;    width: 100%; }
.slider-about__itm {  width: 100%;  height: 100vh;  display: block; }
.slider-about__img {  width: 100%;  height: 100%;  -o-object-fit: cover;  object-fit: cover; }
.slider__dots-lst {  position: absolute;  bottom: 2rem;  left: 50%;
  -webkit-transform: translateX(-50%);  -ms-transform: translateX(-50%);
  transform: translateX(-50%);  display: -webkit-box;  display: -webkit-flex;
  display: -ms-flexbox;  display: flex;  -webkit-box-align: center;
  -webkit-align-items: center;  -ms-flex-align: center;  align-items: center; }
.slider__dots-itm {  width: 10px;  height: 10px;  border-radius: 50%;  background: #fff;
  margin: 0 .5rem;  opacity: 1;  display: inline-block; }
.slider__dots-itm.slick-active {    opacity: 0; }
.slider__dots-itm:first-child {    margin-left: 0; }
.slider__dots-itm:last-child {    margin-right: 0; }
.mpage-about-inner {  display: -webkit-box;  display: -webkit-flex;  display: -ms-flexbox;
  display: flex;  -webkit-box-orient: vertical;  -webkit-box-direction: normal;
  -webkit-flex-flow: column;  -ms-flex-flow: column;  flex-flow: column;
  height: 100%;  width: calc(60% - 40px - 9rem);  padding-bottom: 5rem; }
.mpage-about-info {  font-size: 1.8rem;  text-align: justify;  -webkit-hyphens: auto;
  -ms-hyphens: auto;  hyphens: auto;  letter-spacing: .075em;  margin-bottom: 5rem;
  -webkit-box-flex: 0;  -webkit-flex: none;  -ms-flex: none;  flex: none; }
.mpage-about-info p {    margin-top: 1.5em; }
.mpage-about-disp {  background: #e6e6e6;  padding: 4em 2.5em;
  height: auto;  -webkit-box-flex: 1;  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0px;  flex: 1 0 0; }
.mpage-about-disp__lst {  display: -webkit-box;  display: -webkit-flex;
  display: -ms-flexbox;  display: flex;  -webkit-box-orient: horizontal;  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;  -ms-flex-flow: row wrap;  flex-flow: row wrap;width: 100%; height: 100%; }
.mpage-about-disp__itm {  margin: 1em;  width: calc(20% - 2em);  min-width: 75px; height: 100px; }
.mpage-about-disp--lnk {  width: 100%;  height: 100%; }
.mpage-about-disp__img {  width: 100%;  height: 100%;  -o-object-fit: contain;
  object-fit: contain; }
.mpage-news {  height: 100vh; }
.content_news {  display: -webkit-box;  display: -webkit-flex;  display: -ms-flexbox;
  display: flex;  -webkit-box-orient: horizontal;  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;  -ms-flex-flow: row wrap;  flex-flow: row wrap;
  -webkit-box-pack: justify;  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;  justify-content: space-between; }
.wrap_news {  display: -webkit-box;  display: -webkit-flex;  display: -ms-flexbox;
  display: flex;  width: calc(100% - 40px - 4.5rem);  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;  -webkit-flex-flow: row wrap;  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }
.news-block {  width: 100%; }
.news__lst .slick-track, .news__lst .slick-slide, .news__lst.slick-slider {  width: 100%; }
.news__lst .slick-list {  width: 100%;  padding: 0; }
.news__itm {  width: 50%;  margin: 1.5rem 0; }
.news__lnk {  width: 100%;  height: 230px;  display: -webkit-box;
  display: -webkit-flex;  display: -ms-flexbox;  display: flex; }
.news__lnk:hover .news__pic::after {    opacity: 1; }
.news__lnk:hover .news__title {    font-weight: 700;    color: #66cccc; }
.news__ctrls {  position: absolute;  z-index: 3;  right: 0;
  bottom: 0;  width: 30px;  height: 50px;  background: #999999;
  display: -webkit-box;  display: -webkit-flex;  display: -ms-flexbox;
  display: flex;  -webkit-box-pack: center;  -webkit-justify-content: center;
  -ms-flex-pack: center;  justify-content: center;  -webkit-box-align: center;
  -webkit-align-items: center;  -ms-flex-align: center;  align-items: center; }
.news__ctrls:hover {    background: #009999; }
.news_prev {  right: 50px; }
.news_prev .icon_news {    -webkit-transform: rotate(180deg);    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
.icon_news {  fill: #fff;  width: 20px;  height: 20px; }
.news__content {  width: 100%;  height: 100%;  display: -webkit-box;
  display: -webkit-flex;  display: -ms-flexbox;  display: flex; }
.news__pic {  width: 230px;  height: 100%;  -webkit-box-flex: 0;
  -webkit-flex: none;  -ms-flex: none;  flex: none; }
.news__pic::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #003333;
    mix-blend-mode: color;
    opacity: 0; }

.news__img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover; }

.news__hdr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 2rem 2rem 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0px;
  flex: 1 0 0; }

.news__title {
  font-size: 2rem;
  font-weight: 400;
  color: #333333;
  text-transform: uppercase;
  letter-spacing: .075em;
  margin: 0;
  width: 100%; }

.news__date {
  font-family: 'Rubik';
  margin-top: auto;
  color: #333333;
  font-size: 1.4rem; }

.news__tag {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #0f949b;
  padding: .5rem 1rem;
  margin-top: 1rem; }
  .news__tag:hover {
    background: #003333; }

.news__tag-txt {
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: .075em; }
  .news__tag-txt::before {
    content: '#';
    margin-right: .25rem; }

.news-block__bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 2rem 0; }

.more__lnk {
  display: inline-block;
  margin-left: auto;
  font-weight: 700;
  font-size: 2rem;
  color: #999999;
  text-transform: uppercase; }
  .more__lnk:hover .more__txt::before {
    width: 100%; }

.more__txt::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 0;
  left: 0;
  bottom: -.15rem;
  background-color: #999999;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s; }

.wrap_newslst,
.wrap_newsitm {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.newsitm-block,
.article {
  margin-top: 3rem;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 6rem;
  -moz-column-gap: 6rem;
  column-gap: 6rem;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid; }
  .newsitm-block *,
  .article * {
    font-size: 1.8rem;
    color: #333333;
    line-height: 1.4;
    margin: 1rem 0; }
  .newsitm-block h2,
  .newsitm-block footer,
  .article h2,
  .article footer {
    font-weight: 700;
    font-size: 2rem;
    color: #333333;
    text-transform: uppercase;
    line-height: 1.4; }
  .newsitm-block h2,
  .article h2 {
    margin: 0; }
  .newsitm-block a,
  .article a {
    color: #009999;
    font-weight: 700; }
    .newsitm-block a::before,
    .article a::before {
      content: "";
      position: absolute;
      height: 1px;
      width: 0;
      left: 0;
      bottom: -.25rem;
      background-color: #009999;
      -webkit-transition: all 0.2s ease-in-out 0s;
      -o-transition: all 0.2s ease-in-out 0s;
      transition: all 0.2s ease-in-out 0s; }
    .newsitm-block a:hover::before,
    .article a:hover::before {
      width: 100%; }
  .newsitm-block figure,
  .article figure {
    width: 100%; }
  .newsitm-block img,
  .article img {
    width: 100%;
    height: 350px;
    -o-object-fit: contain;
    object-fit: contain;
    margin: 1rem 0; }
  .newsitm-block figcaption,
  .article figcaption {
    font-weight: 700;
    font-size: 2rem;
    color: #333333;
    text-transform: uppercase;
    letter-spacing: .075em;
    margin: .5rem 0; }
  .newsitm-block footer,
  .article footer {
    margin-top: 1rem; }

.news__lst_newslstpage {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }
  .news__lst_newslstpage .news__itm {
    width: 45%;
    margin: 1rem 2.5%; }

.newsitm-block__bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end; }

.news-ctrls-block {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.news-ctrls {
  width: calc(50% - 3rem);
  border-top: 1px solid #999999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 3rem 0; }

.news-ctrls__itm {
  display: inline-block;
  width: 50%; }
  .news-ctrls__itm:hover .news-ctrls__txt {
    color: #009999; }
  .news-ctrls__itm:hover .icon_news-ctrls__wrap {
    background: #009999; }

.news-ctrls_prev .news-ctrls__txt {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2; }

.news-ctrls_prev .icon_news-ctrls {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.news-ctrls_prev::after {
  content: '\\';
  color: #999999;
  font-size: 4.8rem;
  right: -.5ch;
  top: 0;
  position: absolute;
  line-height: 1; }

.news-ctrls_next .news-ctrls__lnk {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.news-ctrls__lnk {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.news-ctrls__txt {
  font-weight: 700;
  font-size: 1.4rem;
  color: #999999;
  text-transform: uppercase;
  margin: 0 1rem; }

.icon_news-ctrls__wrap {
  width: 30px;
  height: 50px;
  background: #999999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.icon_news-ctrls {
  width: 20px;
  height: 20px;
  fill: #fff; }

.more__lnk_newsitmpage {
  margin: 0;
  margin-left: auto; }

.content_prod {
  width: calc(100% - 190px);
  height: 100%;
  background: #fff; }

.slideshow-block {
  min-width: 400px;
  width: calc(80% - 3rem);
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.slideshow-disp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0px;
  flex: 1 0 0; }

.slideshow-disp__itm {
  padding: 1.5rem 0; }

.slideshow__img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center center;
  object-position: center center; }

.slideshow-thumbs-block {
  width: 20%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 3rem;
  min-width: 120px; }

.slideshow-thumbs {
  width: 100%;
  height: 315px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .slideshow-thumbs .slick-list {
    height: 100%; }
  .slideshow-thumbs .slick-slide {
    height: 105px; }

.slideshow-thumbs__itm {
  padding: .75rem 0; }
  .slideshow-thumbs__itm.slick-current .slideshow-thumbs__lnk {
    background: none; }
  .slideshow-thumbs__itm.slick-current .slideshow-thumbs__img {
    mix-blend-mode: auto; }

.slideshow-thumbs__lnk {
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(230, 230, 230, 0.6);
  border: 1px solid rgba(230, 230, 230, 0.6); }

.slideshow-thumbs__img {
  mix-blend-mode: color; }

.slideshow-thumbs__ctrls {
  position: absolute;
  z-index: 3;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 50px;
  height: 30px;
  background: #999999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .slideshow-thumbs__ctrls:hover {
    background: #009999; }

.slideshow-thumbs_next {
  bottom: -3rem; }

.slideshow-thumbs_prev {
  top: -3rem; }
  .slideshow-thumbs_prev .icon_slideshow-thumbs {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg); }

.icon_slideshow-thumbs {
  fill: #fff;
  width: 20px;
  height: 20px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.slideshow-360 {
  position: absolute;
  bottom: 3rem;
  right: 0;
  cursor: pointer; }

.icon_360 {
  width: 65px;
  height: 65px;
  fill: #0f949b; }

.breadcrumbs_prod {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none; }
  .breadcrumbs_prod .back {
    color: #999999; }
  .breadcrumbs_prod .back::before {
    background-color: #999999; }
  .breadcrumbs_prod .breadcrumbs__txt {
    color: #999999; }
    .breadcrumbs_prod .breadcrumbs__txt::before {
      background-color: #999999; }
  .breadcrumbs_prod .icon_breadcrumbs {
    fill: #999999; }

.wrap_prod {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.prod-desc {
  width: calc(50% - 2.5rem);
  height: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 340px; }

.prod-desc_full {
  width: 100%; }

.prod-desc__content {
  width: calc(100% - 40px - 4.5rem); }
  .prod-desc__content p {
    font-size: 1.8rem;
    color: #333333;
    margin: 1rem 0;
    line-height: 1.6; }

.prod-desc__feature {
  padding: 1.75rem 0 .75rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #999999; }
  .prod-desc__feature:first-child {
    margin-top: 0; }

.prod-desc__feature-name {
  font-weight: 700;
  font-size: 2rem;
  color: #999999;
  text-transform: uppercase; }

.prod-desc__feature-value {
  font-family: 'Rubik';
  font-size: 2rem;
  color: #009999;
  padding-top: .25rem; }

.icon_prod-desc {
  height: 45px;
  width: auto;
  fill: #999999; }

.icon_prod-desc_long {
  height: 25px; }

.prod-desc__addons-title {
  font-weight: 700;
  font-size: 2rem;
  color: #333333;
  text-transform: uppercase;
  margin: 1rem 0 .5rem; }

.prod-desc__addon {
  font-size: 2rem;
  color: #333333;
  text-transform: uppercase;
  margin: .25rem 0; }

.prod-desc__content-download {
  margin-top: 4rem; }

.download__lnk {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .download__lnk:hover .icon_download {
    fill: #009999; }
  .download__lnk:hover .download__txt {
    color: #009999; }

.download__txt {
  font-weight: 700;
  font-size: 2rem;
  color: #999999; }

.icon_download {
  width: 30px;
  height: 30px;
  fill: #999999;
  margin-left: .5rem; }

.prod-desc__variations {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around; }
  .prod-desc__variations .prod-cat__itm {
    width: 35%; }

.position_lst {
  width: 100%; }

.position__itm {
  width: 100%;
  margin: 3rem 0; }
  .position__itm:first-child {
    margin-top: 0; }
  .position__itm:last-child {
    margin-bottom: 0; }

.position__name {
  margin: 0;
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 3rem;
  color: #009999;
  text-transform: uppercase;
  letter-spacing: .075em; }

.faces {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.face-itm {
  width: calc(100%/3 - 20px);
  min-width: 260px;
  height: 400px;
  margin: 15px;
  cursor: pointer; }
  .face-itm:nth-child(3n-2) {
    margin-left: 0; }
  .face-itm:nth-child(3n) {
    margin-right: 0; }
  .face-itm:hover .face-itm__pic::after {
    opacity: 1; }
  .face-itm:hover .face-itm__name {
    font-weight: 700;
    color: #009999; }
  .face-itm:hover .face-itm__position {
    color: #009999;
    border-color: #009999; }

.face-itm__wrap {
  width: 100%;
  height: 100%; }

.face-itm__pic {
  width: 100%;
  height: 100%; }
  .face-itm__pic::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    background: rgba(0, 51, 51, 0.4); }
  .face-itm__pic::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#003333));
    background: -webkit-linear-gradient(top, transparent, #003333);
    background: -o-linear-gradient(top, transparent, #003333);
    background: linear-gradient(to bottom, transparent, #003333);
    z-index: 1;
    mix-blend-mode: multiply; }

.face-itm__img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top center;
  object-position: top center; }

.face-infos {
  display: none; }

.face-itm__info {
  padding: 2em;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1; }

.face-itm__name {
  font-size: 2rem;
  color: #fff;
  letter-spacing: .075em;
  line-height: 1.6;
  text-transform: uppercase; }

.face-itm__position {
  font-size: 1.8rem;
  color: #fff;
  padding-top: .5rem;
  margin-top: 1rem;
  border-top: 1px solid #e6e6e6;
  min-height: 64px; }

.vacancy-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.resume-hdr {
  width: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 2rem;
  margin-bottom: 6rem; }
  .resume-hdr .hr__title {
    width: 100%; }

.hr__title {
  width: 50%;
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: .075em;
  text-transform: uppercase;
  margin: 2rem 0; }

.vacancy-section {
  width: 100%;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  margin-top: 3rem;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid; }

.vacancy-section__itm {
  width: 100%;
  margin: 2rem 0; }
  .vacancy-section__itm:first-child {
    margin-top: 0; }
  .vacancy-section__itm:last-child {
    margin-bottom: 0; }

.vacancy-section__name {
  margin: 0;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e6e6e6;
  font-size: 2rem;
  color: #009999;
  text-transform: uppercase;
  letter-spacing: .075em; }

.vacancy__lst {
  width: 100%; }

.vacancy__itm {
  width: 100%;
  display: inline-block;
  margin: .5rem 0;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  padding: .5rem 0; }
  .vacancy__itm:hover .vacancy__name {
    font-weight: 700;
    color: #009999; }

.vacancy__name {
  font-size: 1.8rem; }

.vacancy__infos {
  display: none; }

.hr-phone {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.icon_phone {
  width: 25px;
  height: 25px;
  fill: #333333; }

.hr-phone__number {
  font-family: 'Rubik';
  font-size: 3rem;
  color: #333333;
  margin-left: .5em; }

.resume-dropdown.active .resume-vacancy-sections {
  opacity: 1;
  visibility: visible;
  top: 102%; }

.resume-vacancy-sections {
  background: #fff;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  top: 125%;
  -webkit-transition: opacity .25s linear, top .25s linear;
  -o-transition: opacity .25s linear, top .25s linear;
  transition: opacity .25s linear, top .25s linear;
  z-index: 1;
  width: 100%;
  height: 200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border: 2px solid #c6e6e6; }

.resume-vacancy-section {
  display: inline-block;
  width: 100%; }

.resume-vacancy-section__name {
  margin: 0;
  padding: 15px 27.5px;
  height: calc(100% - 1.2rem);
  overflow: hidden;
  font-size: 1.8rem;
  color: #009999;
  text-transform: uppercase;
  letter-spacing: .075em;
  border-bottom: 1px solid #c6e6e6; }

.feedback-dropdown.active .themes__lst {
  opacity: 1;
  visibility: visible;
  top: 102%; }

.themes__lst {
  background: #fff;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  top: 125%;
  -webkit-transition: opacity .25s linear, top .25s linear;
  -o-transition: opacity .25s linear, top .25s linear;
  transition: opacity .25s linear, top .25s linear;
  z-index: 1;
  width: 100%;
  height: 200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border: 2px solid #c6e6e6; }

.themes__itm {
  display: inline-block;
  width: 100%;
  padding: 15px 27.5px;
  border-bottom: 1px solid #c6e6e6; }
  .themes__itm:hover {
    background: #c6e6e6; }

.resume-vacancy__itm {
  display: inline-block;
  width: 100%;
  padding: 15px 27.5px; }
  .resume-vacancy__itm:hover {
    background: #c6e6e6; }

.resume-vacancy__lnk,
.themes__lnk {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: calc(100% - 1.2rem);
  overflow: hidden;
  font-size: 1.8rem;
  color: #333333; }

.history-wrap {
  width: 100%; }

.history-lst {
  width: 100%;
  height: 700vh; }

.history-itm {
  padding-top: var(--hdr);
  height: 100vh; }
  .history-itm:nth-child(even) .history-left {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    margin-left: auto; }
  .history-itm:nth-child(even) .history-right {
    left: 0; }
  .history-itm:nth-child(4) .history-right::before {
    background: #0f949b; }
  .history-itm:nth-child(4) .history-hdr__date,
  .history-itm:nth-child(4) .history-title,
  .history-itm:nth-child(4) .history-content {
    color: #fff; }

.history-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  height: 100%; }

.history-left {
  width: 40%;
  height: 100%;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center; }
  .history-left::after {
    content: '';
    left: 50%;
    top: 0;
    position: absolute;
    display: block;
    width: 1px;
    height: 100%;
    border-left: 1px solid #435d3a;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-border-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #435d3a), color-stop(#435d3a), color-stop(transparent), color-stop(transparent), color-stop(#009999), to(#009999)) 100;
    -webkit-border-image: -webkit-linear-gradient(top, #435d3a 0, #435d3a calc(50% - 16rem), transparent calc(50% - 16rem), transparent calc(50% - 9.5rem), #009999 calc(50% - 9.5rem), #009999 100%) 100;
    -o-border-image: -o-linear-gradient(top, #435d3a 0, #435d3a calc(50% - 16rem), transparent calc(50% - 16rem), transparent calc(50% - 9.5rem), #009999 calc(50% - 9.5rem), #009999 100%) 100;
    border-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #435d3a), color-stop(#435d3a), color-stop(transparent), color-stop(transparent), color-stop(#009999), to(#009999)) 100;
    border-image: linear-gradient(to bottom, #435d3a 0, #435d3a calc(50% - 16rem), transparent calc(50% - 16rem), transparent calc(50% - 9.5rem), #009999 calc(50% - 9.5rem), #009999 100%) 100;
    z-index: -1; }
  .history-left::before {
    content: '';
    left: 50%;
    top: calc(50% - 13.5rem);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
    background: #009999;
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%; }

.history-date {
  font-family: 'Rubik';
  font-size: 9rem;
  color: #009999;
  margin-bottom: 5rem;
  margin: 0 auto;
  padding: 2rem 0;
  display: inline-block; }

.history-pic {
  width: 100%;
  height: 350px;
  margin: auto;
  -webkit-transition: ease all .3s;
  -o-transition: ease all .3s;
  transition: ease all .3s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }
  .history-pic::before {
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 2rem 2rem 8rem 0.5rem rgba(51, 51, 51, 0.6);
    box-shadow: 2rem 2rem 8rem 0.5rem rgba(51, 51, 51, 0.6);
    mix-blend-mode: multiply; }

.history-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover; }

.history-right {
  width: 70%;
  height: 100%;
  left: 30%;
  position: absolute;
  overflow: hidden; }
  .history-right::before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: #e6e6e6;
    z-index: -1; }

.history-bg {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  opacity: .2;
  -webkit-filter: blur(2px);
  filter: blur(2px);
  position: absolute;
  z-index: -1; }

.history-article {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 55%;
  height: 100%;
  margin: 0 auto;
  padding: 7rem 0 5rem; }

.history-hdr {
  margin-bottom: 5rem; }

.history-hdr__date {
  font-family: 'Rubik';
  font-size: 2rem;
  font-weight: 700;
  color: #333333;
  letter-spacing: .075em; }

.history-title {
  font-size: 2rem;
  font-weight: 700;
  color: #333333;
  text-transform: uppercase;
  margin: 0;
  margin-top: 1rem;
  letter-spacing: .075em; }

.history-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  font-size: 1.8rem;
  color: #333333;
  line-height: 1.6; }
  .history-content strong {
    font-weight: 700; }
  .history-content a {
    color: #0f949b; }
    .history-content a::before {
      content: "";
      position: absolute;
      height: 1px;
      width: 0;
      left: 0;
      bottom: -.15rem;
      background-color: #0f949b;
      -webkit-transition: all 0.2s ease-in-out 0s;
      -o-transition: all 0.2s ease-in-out 0s;
      transition: all 0.2s ease-in-out 0s; }
    .history-content a:hover::before {
      width: 100%; }

.s-contacts {
  padding-top: var(--hdr); }

.wrap_contacts {
  padding: 2rem 0 4rem 0; }

.contact-places {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.contact-place {
  width: calc(50% - 15px);
  margin: 2rem 0; }

.contact-info__itm {
  margin: .5rem 0; }
  .contact-info__itm:first-child {
    margin-bottom: 2rem; }

.contact-place__title {
  font-weight: 700;
  font-size: 2rem;
  color: #333333;
  text-transform: uppercase;
  margin: 0; }

.contact-place__spec {
  font-size: 2rem;
  color: #333333;
  text-transform: uppercase; }

.contact-info__lnk {
  font-size: 2rem;
  color: #333333; }

.map {
  width: 100%;
  height: 440px;
  margin-top: 2rem; }

.hotline {
  width: 100%;
  background: #0f949b;
  padding: 2rem 1.5rem; }

.hotline__lnk {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.hotline__info {
  width: 45%; }

.hotline__title {
  font-size: 2rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  margin-bottom: 1rem; }

.hotline__txt {
  font-size: 2rem;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.2; }

.logo__hotline {
  height: 95px;
  width: auto; }

.docs-block {
  margin: 3rem 0; }

.docs-block__title {
  margin: 0;
  margin-bottom: .5rem;
  font-size: 2rem;
  text-transform: uppercase; }

.docs__lst {
  width: 100%;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  -webkit-column-gap: 6rem;
  -moz-column-gap: 6rem;
  column-gap: 6rem; }

.docs__itm {
  margin: 2rem 0; }
  .docs__itm:hover .docs__lnk {
    color: #009999; }
  .docs__itm:hover .docs__name {
    font-weight: 700; }
  .docs__itm:hover .docs__download {
    opacity: 1; }
  .docs__itm:hover .icon_download-docs {
    fill: #009999; }

.docs__lnk {
  font-size: 2rem;
  color: #999999;
  letter-spacing: .075rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.docs__name {
  width: 100%;
  text-transform: uppercase; }

.docs__info {
  margin-top: .5rem;
  width: 100%; }

.docs__weight {
  text-transform: uppercase; }

.docs__download {
  opacity: 0; }

.icon_download-docs {
  width: 20px;
  height: 20px; }

.prod-cat {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.prod-cat__itm {
  width: calc(100%/3 - 20px);
  min-width: 260px;
  min-height: 220px;
  margin: 15px;
  border-bottom: 1px solid #999999; }
  .prod-cat__itm:nth-child(3n-2) {
    margin-left: 0; }
  .prod-cat__itm:nth-child(3n) {
    margin-right: 0; }
  .prod-cat__itm:hover {
    border-color: #0f949b; }
    .prod-cat__itm:hover .prod-cat__pic {
      background-color: rgba(230, 230, 230, 0.6); }
    .prod-cat__itm:hover .prod-cat__img {
      mix-blend-mode: color; }
    .prod-cat__itm:hover .prod-cat__name {
      color: #009999; }

.prod-cat__lnk {
  width: 100%;
  height: 100%; }

.prod-cat__inner {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.prod-cat__pic {
  width: 100%;
  height: 160px;
  background: #fff; }

.prod-cat__img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center center;
  object-position: center center;
  mix-blend-mode: auto; }

.prod-cat__title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  padding: 0 2rem; }

.prod-cat__name {
  font-size: 2rem;
  color: #333333;
  text-transform: uppercase; }

.hero__title-img_action {
  z-index: 2;
  width: 100%;
  -webkit-transform-origin: 25% 100%;
  -ms-transform-origin: 25% 100%;
  transform-origin: 25% 100%;
  -webkit-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg); }

.hero__title-addon-pic {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  width: 80%; }
  .hero__title-addon-pic span {
    width: calc(80% - 10px);
    margin-left: 10px; }

.hero__title-addon-img {
  width: 20%;
  z-index: 1; }

.hdr {
  width: 100%;
  position: absolute;
  z-index: 5;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  -webkit-transition: all 1s ease, background-color 0s, border-color 0s;
  -o-transition: all 1s ease, background-color 0s, border-color 0s;
  transition: all 1s ease, background-color 0s, border-color 0s; }
  .hdr.hdr_mainpage {
    background-color: transparent;
    border-bottom: 1px solid transparent; }
    .hdr.hdr_mainpage .hdr__inner {
      height: auto;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start; }
    .hdr.hdr_mainpage .hdr__logo {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start; }
    .hdr.hdr_mainpage .logo__b {
      height: 190px;
      background: transparent;
      padding: 1.5em;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .hdr.hdr_mainpage .logo__b .logo {
        fill: #fff;
        width: 100%; }
    .hdr.hdr_mainpage .logo__m {
      width: 185px;
      height: 55px;
      margin: 1.5em 0 0 3em; }
      .hdr.hdr_mainpage .logo__m .logo {
        fill: #fff; }
    .hdr.hdr_mainpage .hdr-nav {
      padding-top: 1.5em; }
    .hdr.hdr_mainpage .hdr-nav__txt {
      color: #fff;margin-top: 15px;  }
    .hdr.hdr_mainpage .icon_down {
      fill: #fff; }
    .hdr.hdr_mainpage .burger {
      border-color: #fff; }
    .hdr.hdr_mainpage .lang__lst .hdr-nav__txt {
      color: #0f949b; }
    .hdr.hdr_mainpage.showActivity .hdr-nav {
      top: 0; }
  .hdr.sticked {
    -webkit-transition: all 1s ease, background-color 0s, border-color 0s;
    -o-transition: all 1s ease, background-color 0s, border-color 0s;
    transition: all 1s ease, background-color 0s, border-color 0s;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 5; }

.hdr__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: var(--hdr); }

.hdr__logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.logo__b {
  width: 190px;
  height: auto;
  background: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-transition: all .5s ease, background-color 0s;
  -o-transition: all .5s ease, background-color 0s;
  transition: all .5s ease, background-color 0s; }
  .logo__b .logo {
    fill: #0f949b;
    width: 110px; }

.logo {
  width: 100%; }

.logo__m {
  width: 100%;
  height: auto;
  display: block;
  margin-left: 3em;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease; }
  .logo__m .logo {
    fill: #999999; }

.hdr-nav__lst {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.hdr-nav-itm {
  margin: 0 1.5em;
  cursor: pointer; }

.hdr-nav__lnk {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none; }

.hdr-nav__txt {
  font-size: 1.8rem;
  color: #0f949b;
  text-transform: uppercase; }

.hdr.showActivity .lang,
.hdr.showActivity .hdr-nav__menu {
  display: none; }

.hdr.showActivity .hdr-nav {
  position: absolute;
  top: 1.5em;
  right: 0;
  width: 380px; }

.hdr.showActivity .hdr-nav__lst {
  z-index: 2;
  background: #66cccc;
  padding: 1em .5em; }

.hdr.showActivity .hdr-nav__txt {
  color: #fff;
  z-index: 3; }

.hdr.showActivity .hdr-nav__lnk-inner {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.hdr.showActivity .icon_close-activity {
  display: block; }

.hdr.showActivity .activity__lst {
  display: block; }

.icon_close-activity {
  width: 15px;
  height: 15px;
  fill: #fff;
  display: none; }

.activity__lst {
  display: none;
  width: 100%;
  margin-top: 2em;
  padding: 0 1em; }

.activity__itm {
  margin: 1em 0; }
  .activity__itm:hover {
    font-weight: 700; }

.activity__txt {
  font-size: 1.8rem;
  color: #fff;
  text-transform: uppercase; }

.lang {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none; }
  .lang.active .lang__lst {
    opacity: 1;
    visibility: visible;
    top: 102%; }
  .lang.active .icon_down {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg); }

.lang-input {
  display: none; }

.lang-current {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.lang__lst {
  background: #fff;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  top: 125%;
  left: -.75em;
  width: calc(100% + .5em + 6px);
  -webkit-transition: opacity .25s linear, top .25s linear;
  -o-transition: opacity .25s linear, top .25s linear;
  transition: opacity .25s linear, top .25s linear;
  z-index: 1;
  height: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: .5em;
  padding-right: 6px; }
  .lang__lst .icon_flag {
    margin-right: 0; }

.lang__itm {
  padding: .25em;
  margin: .25em 0; }
  .lang__itm:hover {
    background: #e6e6e6; }

.lang__lnk {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.icon_flag {
  width: 20px;
  height: 20px;
  margin: 0 .75em;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none; }

.icon_down {
  width: 10px;
  height: 10px;
  fill: #0f949b;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.hdr-nav__menu {
  margin-right: 0;
  cursor: pointer;
  width: 25px;
  height: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none; }
  .hdr-nav__menu:hover .burger_top {
    top: 2px; }
  .hdr-nav__menu:hover .burger_bottom {
    top: 14px; }

.burger {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  left: 0;
  border-top: 4px solid #0f949b;
  width: 100%;
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s; }

.burger_top {
  top: 0; }

.burger_middle {
  top: 8px; }

.burger_bottom {
  top: 16px; }

.ftr {
  margin-top: 5rem; }

.ftr__top {
  background: #e6e6e6;
  padding: 2rem 0; }

.ftr__lnks-lst {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 60%;
  -ms-flex: 1 0 60%;
  flex: 1 0 60%;
  margin-right: 30px; }

.ftr__lnks-itm {
  margin: 0 1rem; }
  .ftr__lnks-itm:first-child {
    margin-left: 0; }
  .ftr__lnks-itm:last-child {
    margin-right: 0; }

.ftr__lnk-itm {
  margin: 1rem 0; }
  .ftr__lnk-itm:hover .ftr__lnk::before {
    width: 100%; }

.ftr__lnk {
  font-size: 1.4rem;
  line-height: 1.2;
  color: #333333;
  text-transform: uppercase; }
  .ftr__lnk::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 0;
    left: 0;
    bottom: -.15rem;
    background-color: #999999;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s; }

.ftr__title {
  font-weight: 700;
  font-size: 2rem;
  margin: 1.5rem 0;
  color: #0f949b;
  text-transform: uppercase;
  letter-spacing: .075em; }

.ftr__title_contacts {
  font-weight: 400;
  color: #333333; }

.ftr__contacts-itm {
  margin: .75rem 0; }
  .ftr__contacts-itm:hover .ftr__lnk::before {
    width: 100%; }

.socials {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 1em; }

.socials__itm {
  margin: 0 .5em; }
  .socials__itm:first-child {
    margin-left: 0; }
  .socials__itm:hover .icon_socials {
    fill: #0f949b; }

.socials__lnk {
  width: 100%;
  height: 100%; }

.icon_socials {
  width: 20px;
  height: 20px;
  fill: #999999; }

.ftr__bottom {
  padding: 1em 0; }

.content_ftr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.ftr__copyright {
  font-family: 'Rubik';
  font-size: 1.4rem;
  color: #333333;
  text-transform: uppercase;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.ftr__logo-lst {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 60%; }

.ftr__logo-itm {
  height: auto;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-filter: gray;
  filter: gray;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .ftr__logo-itm:hover {
    -webkit-filter: none;
    filter: none; }
  .ftr__logo-itm:first-child {
    width: 115px; }
    .ftr__logo-itm:first-child .logo {
      fill: #66cccc; }
  .ftr__logo-itm:nth-child(2) {
    width: 145px; }
  .ftr__logo-itm:last-child {
    width: 190px; }
  .ftr__logo-itm .logo {
    fill: #0f949b; }

.ftr__logo-lnk {
  width: 100%;
  height: 100%; }

.first-screen-content {
  width: calc(100% - 190px);
  height: 100%;
  background: #fff;
  padding-top: var(--hdr); }

.first-screen-pic {
  width: 100%;
  height: calc(100% - var(--hdr));
  position: absolute;
  top: var(--hdr);
  left: 0;
  z-index: 0; }

.first-screen-img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top center;
  object-position: top center;
  width: 100%;
  height: 100%; }

.first-screen-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  height: 100%;
  padding: 3rem 0; }

.first-screen-info {
  width: 70%;
  display: table; }

a.first-screen-info:hover .first-screen-title {
  color: #0f949b; }

.first-screen-title {
  font-weight: 400;
  font-size: 4rem;
  letter-spacing: .075em;
  color: #fff;
  text-transform: uppercase;
  margin: 0; }

.first-screen-addon {
  margin-top: 5rem; }

.first-screen-date {
  font-family: 'Rubik';
  font-size: 2rem;
  color: #009999; }

.first-screen-tag {
  margin-left: auto; }
  .first-screen-tag .news__tag {
    margin-top: 0; }

.first-screen-nav {
  width: 90%; }

.first-screen-lst {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.first-screen-itm {
  margin: 1rem 1.5rem;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: auto; }
  .first-screen-itm:hover .first-screen-lnk::before {
    width: 100%; }

.first-screen-lnk {
  font-weight: 700;
  font-size: 2rem;
  color: #fff;
  letter-spacing: .075em;
  text-transform: uppercase;
  display: inline-block; }
  .first-screen-lnk::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 0;
    left: 0;
    bottom: -.25rem;
    background-color: #fff;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s; }

.first-screen-contacts {
  width: 70%; }

.first-screen__contact-itm {
  font-size: 2rem;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .075em;
  margin: .5rem 0; }

.first-screen__contact-lnk {
  font-size: 2rem;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .075em; }

.popup__bg {
  display: none; }

.wrap_popup::after {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background:rgba(67,93,58, 0.6); }

.body.showMenu {
  overflow: hidden; }
  .body.showMenu .up,
  .body.showMenu .down {
    display: none !important; }
  .body.showMenu .hdr-nav__txt {
    text-shadow: 0px 0px .5em #fff, 0px 0px 1em #fff; }
  .body.showMenu .burger {
    -webkit-box-shadow: 0px 0px .5em #fff;
    box-shadow: 0px 0px .5em #fff; }
  .body.showMenu .sticked .burger {
    -webkit-box-shadow: none;
    box-shadow: none; }
  .body.showMenu .logo__m .logo,
  .body.showMenu .icon_down,
  .body.showMenu .icon_flag {
    -webkit-filter: drop-shadow(0px 0px 0.25em #fff);
    filter: drop-shadow(0px 0px 0.25em #fff); }
  .body.showMenu .hdr-nav__menu:active .burger_top, .body.showMenu .hdr-nav__menu:focus .burger_top {
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg); }
  .body.showMenu .hdr-nav__menu:active .burger_bottom, .body.showMenu .hdr-nav__menu:focus .burger_bottom {
    -webkit-transform: rotate(-40deg);
    -ms-transform: rotate(-40deg);
    transform: rotate(-40deg); }
  .body.showMenu .burger_top {
    top: 10px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: 0.15s 0.1s;
    -o-transition: 0.15s 0.1s;
    transition: 0.15s 0.1s; }
  .body.showMenu .burger_middle {
    opacity: 0; }
  .body.showMenu .burger_bottom {
    top: 10px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: 0.15s 0.1s;
    -o-transition: 0.15s 0.1s;
    transition: 0.15s 0.1s; }
  .body.showMenu .wrap {
    -webkit-filter: blur(4px);
    filter: blur(4px); }
  .body.showMenu .wrap_menu {
    opacity: 1;
    z-index: 4; }

.body.showCategory {
  overflow: hidden; }
  .body.showCategory .up,
  .body.showCategory .down {
    display: none !important; }
  .body.showCategory .wrap {
    -webkit-filter: blur(4px);
    filter: blur(4px); }
  .body.showCategory .hdr-nav__txt {
    text-shadow: 0px 0px .5em #fff, 0px 0px 1em #fff; }
  .body.showCategory .burger {
    -webkit-box-shadow: 0px 0px .5em #fff;
    box-shadow: 0px 0px .5em #fff; }
  .body.showCategory .logo__m .logo,
  .body.showCategory .icon_down,
  .body.showCategory .icon_flag {
    -webkit-filter: drop-shadow(0px 0px 0.25em #fff);
    filter: drop-shadow(0px 0px 0.25em #fff); }
  .body.showCategory .wrap_category {
    left: 0;
    opacity: 1;
    z-index: 4; }

.body.showFaces {
  overflow: hidden; }
  .body.showFaces .up,
  .body.showFaces .down {
    display: none !important; }
  .body.showFaces .wrap {
    -webkit-filter: blur(4px);
    filter: blur(4px); }
  .body.showFaces .hdr-nav__txt {
    text-shadow: 0px 0px .5em #fff, 0px 0px 1em #fff; }
  .body.showFaces .burger {
    -webkit-box-shadow: 0px 0px .5em #fff;
    box-shadow: 0px 0px .5em #fff; }
  .body.showFaces .logo__m .logo,
  .body.showFaces .icon_down,
  .body.showFaces .icon_flag {
    -webkit-filter: drop-shadow(0px 0px 0.25em #fff);
    filter: drop-shadow(0px 0px 0.25em #fff); }
  .body.showFaces .wrap_faces {
    left: 0;
    opacity: 1;
    z-index: 4; }

.body.showVacancy {
  overflow: hidden; }
  .body.showVacancy .up,
  .body.showVacancy .down {
    display: none !important; }
  .body.showVacancy .wrap {
    -webkit-filter: blur(4px);
    filter: blur(4px); }
  .body.showVacancy .hdr-nav__txt {
    text-shadow: 0px 0px .5em #fff, 0px 0px 1em #fff; }
  .body.showVacancy .burger {
    -webkit-box-shadow: 0px 0px .5em #fff;
    box-shadow: 0px 0px .5em #fff; }
  .body.showVacancy .logo__m .logo,
  .body.showVacancy .icon_down,
  .body.showVacancy .icon_flag {
    -webkit-filter: drop-shadow(0px 0px 0.25em #fff);
    filter: drop-shadow(0px 0px 0.25em #fff); }
  .body.showVacancy .vacancy_popup {
    left: 0;
    opacity: 1;
    z-index: 4; }

.wrap_menu {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0; }

.content_menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%; }

.sidebar_menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-bottom: 2.5em; }

.menu-logo-lnk {
  width: 100%;
  padding: .5em;
  background: #fff; }

.content_menu-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  padding: calc(var(--hdr) + 12rem) 0 2.5em; }

.menu {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0%;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  padding-bottom: 4em; }

.menu__lst {
  width: 40%; }
.submenu__lnk:hover{
   color: #b9d87c;text-shadow: 1px 1px 2px #460B09;
}
.menu__itm {
  width: 100%;
  display: block;
  margin: .5rem 0;
  padding: 1rem 0;
  position: static;
  cursor: pointer; }
  .menu__itm:hover .menu__txt::before {
    width: 100%; }
  .menu__itm.active .icon_menu {
    opacity: 1; }
  .menu__itm.active .menu__txt::before {
    width: 100%; }
  .menu__itm.active .submenu {
    opacity: 1;
    z-index: 1; }
  .menu__itm.menu__itm_noattach .icon_menu {
    opacity: 0; }
  .menu__itm.menu__itm_noattach .menu__lnk {
    pointer-events: auto; }

.menu__itm_noattach .menu__lnk {
  pointer-events: none; }

.menu__lnk {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.menu__txt {
  font-weight: 700;
  font-size: 2rem;text-shadow: 1px 1px 2px #460B09;
  letter-spacing: .075em;
  color: #fff;
  text-transform: uppercase; }
  .menu__txt:before {
    content: "";
    position: absolute;
    height: 1px;
    width: 0;
    left: 0;
    bottom: -.25rem;
    background-color: #fff;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s; }

.icon_menu {
  opacity: 0; }

.submenu {
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 1rem;
  left: 100%;
  width: 120%;
  padding-left: 2rem;
  height: 50vh; }

.submenu__itm {
  width: 100%;
  display: block;
  margin: 2rem 0; }
  .submenu__itm:first-child {
    margin-top: 0; }
  .submenu__itm:hover .submenu__lnk::before { 
    width: 100%; }

.submenu__lnk {
  font-weight: 700;
  font-size: 1.8rem;
  letter-spacing: .075em;
  color: #fff;
  text-transform: uppercase; }
  .submenu__lnk:before {
    content: "";
    position: absolute;
    height: 1px;
    width: 0;
    left: 0;
    bottom: -.25rem;
    background-color: #fff;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s; }

.menu__copyright {
  display: inline-block;
  margin-top: auto;
  color: #fff; }

.menu__socials {
  margin-top: auto; }
  .menu__socials .socials__itm:hover .socials__lnk {
    background-color: #66cccc; }
  .menu__socials .socials__itm:hover .icon_socials {
    fill: #fff; }
  .menu__socials .socials__lnk {
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background: transparent;
    border-radius: 2px; }
  .menu__socials .icon_socials {
    fill: #fff; }

.wrap_category {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: -100%;
  right: 0;
  opacity: 0; }

.content_category {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.sidebar_category::before {
  content: '';
  width: 175%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -75%;
  right: 0;
  background: #0f949b; }

.content_category-inner {
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.category-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  height: 100%; }

.category-popup__pic::after {
  opacity: 0; }

.category-popup__info {
  width: 100%;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.category-popup__name {
  width: 40%; }

.subcategory__wrap {
  width: 60%;
  padding: 2rem;
  margin-top: calc(var(--hdr) + 7rem); }

.icon_close-category {
  top: -2rem;
  right: 56px; }

.subcategory__lst {
  height: 60vh; }
  .subcategory__lst::-webkit-scrollbar-thumb {
    background: #fff; }

.subcategory__itm {
  margin: .75rem 0; }
  .subcategory__itm:first-child {
    margin-top: 0; }
  .subcategory__itm:hover .subcategory__lnk::before {
    width: 100%; }

.subcategory__lnk {
  font-size: 2rem;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase; }
  .subcategory__lnk::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 0;
    left: 0;
    bottom: -.15rem;
    background-color: #e6e6e6;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s; }

.wrap_faces {
  width: 100%;
  height: calc(100vh - var(--hdr));
  position: fixed;
  top: var(--hdr);
  left: -100%;
  right: 0;
  opacity: 0;
  z-index: -1; }

.content_faces {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 7rem 0 4rem; }

.icon_close-faces {
  right: 0;
  top: 3rem; }

.faces__left {
  width: calc(100%/3 - 30px);
  height: 430px; }

.face__pic {
  width: 100%;
  height: 100%;
  display: block; }

.face__img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top center;
  object-position: top center; }

.face__position {
  font-size: 2rem;
  color: #fff;
  letter-spacing: .075em;
  text-transform: uppercase;
  padding: 1.5rem;
  line-height: 1.2; }

.faces__right {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  margin-left: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap; }

.face__name {
  font-size: 4rem;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .075em; }

.face__info-lst {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  margin-top: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.face__info {
  margin: 1.5rem 0; }
  .face__info:first-child {
    margin-top: 0; }
  .face__info:last-child {
    margin-bottom: 0; }

.face__info-title {
  font-size: 2rem;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 15px; }

.face__info-value {
  font-size: 1.8rem;
  color: #fff;
  line-height: 1.6; }

.face__info-born {
  font-family: 'Rubik';
  font-size: 4rem;
  color: #fff;
  letter-spacing: .075em;
  line-height: 1; }

.face__workexp {
  margin-top: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap; }

.face__workexp-title {
  font-weight: 700;
  font-size: 2rem;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .075em; }

.face__workexp-ls {
  margin-top: 30px; }

.face-work {
  padding: 2rem 0 2rem calc(4rem + 25px); }
  .face-work::before {
    content: '';
    width: 1px;
    height: 100%;
    border-left: 1px solid #fff;
    position: absolute;
    top: 0;
    left: 12.5px;
    display: block; }
  .face-work:hover .face-work__dates::before {
    width: 25px;
    height: 25px;
    left: calc(-4rem - 24.5px); }

.face-work__dates {
  font-family: 'Rubik';
  font-size: 4rem;
  color: #fff;
  letter-spacing: .075em;
  margin-bottom: .75rem; }
  .face-work__dates::before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    display: block;
    position: absolute;
    left: calc(-4rem - 20px);
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease; }

.face-work__exp {
  font-size: 1.8rem;
  color: #fff;
  letter-spacing: .075em;
  text-transform: uppercase;
  line-height: 1.2; }

.vacancy_popup {
  width: 100%;
  height: calc(100% - var(--hdr));
  position: fixed;
  top: var(--hdr);
  left: -100%;
  right: 0;
  opacity: 0;
  z-index: -1;
  height: calc(100vh - var(--hdr)); }
  .vacancy_popup.showResume .btn_resume,
  .vacancy_popup.showResume .vacancy-detail {
    display: none; }
  .vacancy_popup.showResume .popup-resume-content,
  .vacancy_popup.showResume .popup-resume__title {
    display: block; }
  .vacancy_popup.showResume .popup-vacancy__name {
    font-size: 2rem; }

.content_vacancy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  height: 100%; }

.vacancy-left {
  height: 100%;
  background: rgba(0, 51, 51, 0.4);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  padding: 5rem 0; }

.popup-resume__title {
  display: none;
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  letter-spacing: .075em;
  text-transform: uppercase;
  margin-bottom: 5rem; }

.popup-vacancy__name {
  font-size: 3rem;
  color: #fff;
  letter-spacing: .075em;
  text-transform: uppercase; }

.vacancy-summary {
  margin-top: auto;
  margin-bottom: 3rem; }

.vacancy-summary__itm {
  margin: 1.5rem 0; }

.vacancy-summary__name {
  font-size: 2rem;
  color: #fff;
  text-transform: uppercase; }

.vacancy-summary__value {
  font-family: 'Rubik';
  font-size: 2rem;
  color: #fff;
  margin-top: .25rem; }

.vacancy-right {
  height: 100%;
  padding: 7rem 0 5rem; }

.popup-resume-content {
  display: none;
  padding: 0 5rem 0 2rem; }
  .popup-resume-content .form-itm_required .form-itm__title {
    color: #99cccc; }
  .popup-resume-content .form-itm__title {
    width: 40%;
    color: #fff; }
    .popup-resume-content .form-itm__title span:last-child {
      color: #fff; }
  .popup-resume-content .form-itm__value {
    width: 60%; }
  .popup-resume-content .form-ftr__top {
    width: 100%; }
  .popup-resume-content .agree__checkbox {
    background: #fff; }
  .popup-resume-content .agree__txt {
    color: #fff; }
  .popup-resume-content .agree__lnk {
    color: #99cccc; }
  .popup-resume-content .form-ftr__bottom {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .popup-resume-content .recaptcha,
  .popup-resume-content .form-confirm {
    width: calc(50% - 15px); }
  .popup-resume-content .form-confirm {
    margin-left: 0; }

.vacancy-detail {
  padding: 0 5rem 0 2rem; }

.vacancy-detail__itm {
  margin: 2.5rem 0; }
  .vacancy-detail__itm:first-child {
    margin-top: 0; }

.vacancy-detail__title {
  margin: 0;
  margin-bottom: .5rem;
  font-size: 2rem;
  color: #fff;
  letter-spacing: .075em;
  text-transform: uppercase; }

.vacancy-expect__itm {
  font-size: 1.4rem;
  color: #fff;
  margin: 1rem 0;
  padding-left: 2rem;
  line-height: 1.2; }
  .vacancy-expect__itm::before {
    content: '—';
    position: absolute;
    left: 0;
    font-size: 1.4rem;
    color: #fff; }

.wrap_catalog {
  width: 50%;
  height: calc(100% - var(--hdr));
  position: fixed;
  top: var(--hdr);
  left: -100%;
  right: 0;
  opacity: 0;
  z-index: -1;
  height: calc(100vh - var(--hdr)); }
  .wrap_catalog::before {
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    height: 100%;
    background: rgba(0, 51, 51, 0.6); }
  .wrap_catalog.active {
    opacity: 1;
    left: 50%;
    z-index: 5; }

.content_cat {
  margin: 0 20% 0 10%;
  padding: var(--hdr) 0;
  height: 100%; }

.popup-cat__inner {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.popup-cat__pic {
  height: 230px;
  width: 100%;
  background: #fff;
  padding: 1rem; }

.popup-cat__img {
  -o-object-position: center;
  object-position: center;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%; }

.popup-cat__info {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.popup-cat__datas {
  width: 100%; }

.cat__data {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  padding: .5rem 0;
  margin: 2rem 0; }

.cat__data-name {
  font-weight: 700;
  font-size: 2rem;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .075em; }

.cat__data-value {
  font-size: 2rem;
  color: #fff; }

.popup-cat__lnk {
  margin: auto 0 0 auto; }
  .popup-cat__lnk .more__lnk {
    color: #fff; }
  .popup-cat__lnk .more__txt::before {
    background-color: #fff; }

.form-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.form-hdr {
  width: 50%;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 2rem;
  margin-bottom: 6rem; }

.form__title {
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: .075em;
  text-transform: uppercase;
  margin: 2rem 0; }

.form-lst {
  width: 100%; }

.form-itm {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  padding: 2rem 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.form-itm__title {
  width: 30%;
  font-size: 2rem;
  letter-spacing: .075em;
  text-transform: uppercase; }

.form-itm__value {
  width: 40%; }

.form-dropdown {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none; }
  .form-dropdown.active .icon_down {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg); }

.form-dropdown__lnk {
  display: block;
  height: calc(100% - 1.2rem);
  overflow: hidden; }

.form-dropdown__input {
  display: none; }

.form-dropdown__current {
  width: 100%;
  height: 60px;
  background: #c6e6e6;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 var(--hdr) 0 20px;
  font-size: 1.8rem;
  color: #333333;
  border: 2px solid #c6e6e6;
  overflow: hidden; }

.form-dropdown__btn {
  position: absolute;
  width: 60px;
  height: 100%;
  right: 0;
  top: 0;
  background: #99cccc;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 2; }
  .form-dropdown__btn .icon_down {
    fill: #fff;
    width: 20px;
    height: 20px; }

.input {
  width: 100%;
  height: 40px;
  border: 2px solid #c6e6e6;
  padding: 0 20px;
  font-size: 1.8rem;
  color: #333333; }
  .input:focus {
    background: #c6e6e6; }
    .input:focus::-webkit-input-placeholder {
      color: #333333; }
    .input:focus:-ms-input-placeholder {
      color: #333333; }
    .input:focus::placeholder {
      color: #333333; }
    .input:focus::-webkit-input-placeholder {
      color: #333333; }
    .input:focus::-moz-placeholder {
      color: #333333; }
    .input:focus:-ms-input-placeholder {
      color: #333333; }
  .input::-webkit-input-placeholder {
    font-family: 'Source Code Pro';
    color: #333333;
    font-size: 1.8rem; }
  .input:-ms-input-placeholder {
    font-family: 'Source Code Pro';
    color: #333333;
    font-size: 1.8rem; }
  .input::placeholder {
    font-family: 'Source Code Pro';
    color: #333333;
    font-size: 1.8rem; }
  .input::-webkit-input-placeholder {
    font-family: 'Source Code Pro';
    color: #333333;
    font-size: 1.8rem; }
  .input::-moz-placeholder {
    font-family: 'Source Code Pro';
    color: #333333;
    font-size: 1.8rem; }
  .input:-ms-input-placeholder {
    font-family: 'Source Code Pro';
    color: #333333;
    font-size: 1.8rem; }

.attach {
  width: auto;
  overflow: hidden; }
  .attach:hover .btn_attach {
    background-color: #003333; }

.attach__input {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0; }

.form-ftr {
  margin-top: 6rem; }

.form-ftr__top {
  width: 70%; }

.form-ftr__bottom {
  width: 100%;
  margin-top: 6rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.agree__label {
  height: 100%;
  width: 100%;
  display: block; }

.agree__input {
  display: none; }
  .agree__input:checked + .agree__inner .icon_check {
    opacity: 1; }
  .agree__input:not(checked) + .agree__inner .icon_check {
    opacity: 0; }

.agree__inner {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }

.agree__checkbox {
  width: 15px;
  height: 15px;
  border: 1px solid #99cccc;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer; }

.agree__txt {
  font-size: 1.6rem;
  color: #333333;
  line-height: 1.6;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  margin-left: 15px; }

.agree__lnk {
  color: #009999; }
  .agree__lnk:hover {
    font-weight: 700; }

.recaptcha {
  width: 30%; }

.recaptcha__pic {
  width: 100%; }

.recaptcha__img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover; }

.resume-recaptcha__img {
  width: 100%; }

.form-confirm {
  width: 30%;
  margin-left: 40%; }

.form-itm_textarea {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }
  .form-itm_textarea .form-itm__title {
    padding-top: 15px; }

.form__textarea {
  width: 100%;
  height: 300px;
  resize: none;
  outline: none;
  -moz-appearance: none;
  overflow: hidden;
  border: 2px solid #c6e6e6;
  padding: 15px 20px;
  font-size: 1.8rem;
  color: #333333; }
  .form__textarea:focus {
    background: #c6e6e6; }
    .form__textarea:focus::-webkit-input-placeholder {
      color: #333333; }
    .form__textarea:focus:-ms-input-placeholder {
      color: #333333; }
    .form__textarea:focus::placeholder {
      color: #333333; }
    .form__textarea:focus::-webkit-input-placeholder {
      color: #333333; }
    .form__textarea:focus::-moz-placeholder {
      color: #333333; }
    .form__textarea:focus:-ms-input-placeholder {
      color: #333333; }
  .form__textarea::-webkit-input-placeholder {
    font-family: 'Source Code Pro';
    color: #333333;
    font-size: 1.8rem; }
  .form__textarea:-ms-input-placeholder {
    font-family: 'Source Code Pro';
    color: #333333;
    font-size: 1.8rem; }
  .form__textarea::placeholder {
    font-family: 'Source Code Pro';
    color: #333333;
    font-size: 1.8rem; }
  .form__textarea::-webkit-input-placeholder {
    font-family: 'Source Code Pro';
    color: #333333;
    font-size: 1.8rem; }
  .form__textarea::-moz-placeholder {
    font-family: 'Source Code Pro';
    color: #333333;
    font-size: 1.8rem; }
  .form__textarea:-ms-input-placeholder {
    font-family: 'Source Code Pro';
    color: #333333;
    font-size: 1.8rem; }

@media (max-width: 1065px) and (min-width: 1025px) {
  .first-screen-inner_prod {
    width: 90%; }
  .prod-cat__itm {
    min-width: 250px; } }

@media (max-width: 1024px) {
    .logo__b img{ display: block !important;}

  .content_c {
    width: 90%; }
  .content_r,
  .content_l {
    margin: 0 auto; }
  .first-screen-content {
    width: calc(100% - 150px); }
  .sidebar {
    width: 150px; }
  .title {
    -webkit-transform: rotate(-90deg) translate(calc(-200vh/3 + 2rem), -50%);
    -ms-transform: rotate(-90deg) translate(calc(-200vh/3 + 2rem), -50%);
    transform: rotate(-90deg) translate(calc(-200vh/3 + 2rem), -50%);
    left: 50%;
    font-size: 3.5rem; }
  .down,
  .up {
    display: none !important;
    opacity: 0 !important; }
  .hdr__inner {
    position: static; }
  .hdr.hdr_mainpage .hdr__logo {
    position: absolute;
    left: 0; }
  .hdr.hdr_mainpage .logo__b {
    height: 150px; }
  .hdr.hdr_mainpage .logo__m {
    width: 150px; }
  .logo__b {
    width: 150px; }
  .hdr-nav {
    margin-left: auto; }
  .main .sidebar__title_hero {
    -webkit-transform: rotate(-90deg) translate(-90vh, calc(150px - 200%));
    -ms-transform: rotate(-90deg) translate(-90vh, calc(150px - 200%));
    transform: rotate(-90deg) translate(-90vh, calc(150px - 200%));
    left: 0; }
  .hero__dots-lst {
    left: 2.5%; }
  .s-category .content {
    width: calc(100% - 150px); }
  .s-category .inner {
    width: 100%; }
  .category__lst {
    display: flex;overflow: scroll;
    /*-webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding-top: var(--hdr);*/ }
    /*.category__lst:hover .category__itm {
      width: calc(100%/3); }
      .category__lst:hover .category__itm:hover {
        width: calc(100%/3); }*/
  /*.category__itm {
    width: calc(100%/3);
    height: 50%; }*/
  .mpage-about {
    height: auto; }
  .content_about {
    width: 100%; }
  .wrap_slider {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    width: calc(100% - 150px);
    height: 300px;
    margin-left: 150px;
    padding: 0 2rem; }
  .slider-about__itm {
    height: 100%; }
  .mpage-about-inner {
    width: calc(100% - 150px);
    padding: 2rem;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; }
  .mpage-about-info {
    margin-bottom: 2rem;
    width: 100%; }
  .mpage-about-disp {
    display: none; }
  .mpage-news {
    height: auto; }
  .content_news {
    width: 100%; }
  .wrap_news {
    width: calc(100% - 150px);
    padding-right: 2rem; }
  .news__itm {
    width: 50%;
    display: inline-block;
    margin: 0;
    padding: 1.5rem;
    padding-right: 0; }
  .news__lnk {
    height: auto; }
  .news__content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; }
  .news__pic {
    width: 100%;
    height: 200px; }
  .news__hdr {
    padding: 0;
    width: 100%;
    height: auto;
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none; }
  .news__title {
    padding: 1.5rem 0; }
  .news__tag {
    position: absolute;
    top: 1.5rem;
    margin: 0;
    right: 1.5rem; }
  .news__ctrls {
    bottom: -50px; }
  .news-block__bottom {
    margin-top: 7rem; }
  .ftr__lnks-lst {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; }
  .ftr__lnks-itm {
    max-width: calc(100%/3 - 2rem); }
  .ftr__copyright {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    text-align: center;
    margin-top: 1rem; }
  .ftr__logo-lst {
    width: 100%; }
  .content_menu {
    width: 100%; }
  .menu__txt {
    font-size: 1.8rem; }
  .submenu {
    width: 140%; }
  .submenu__lnk {
    font-size: 1.6rem; }
  .menu__copyright {
    margin-top: 0; }
  .content_category {
    width: 100%; }
  .sidebar_category {
    display: none; }
  .icon_close-category {
    right: 4.25%; }
  .subcategory__lnk {
    font-size: 1.8rem; }
  .first-screen .content_r {
    width: 100%; }
  .content_faces {
    padding: 4rem 0; }
  .faces__left {
    height: 300px; }
  .face__name,
  .face__info-born,
  .face-work__dates {
    font-size: 3rem; }
  .face__info-title {
    font-size: 1.8rem; }
  .face__workexp {
    margin-top: 30px; }
  .face__workexp-title {
    padding: .5rem 0; }
  .face-work {
    padding: 1rem 0 1rem calc(4rem + 25px); }
  .hr__title {
    width: 100%;
    font-size: 1.8rem; }
  .vacancy-section {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2; }
  .resume-hdr {
    width: 100%;
    margin-bottom: 2rem; }
  .hr-phone__number {
    font-weight: 700;
    font-size: 2rem; }
  .resume-content {
    width: 100%; }
  .form-itm__title {
    width: 50%; }
  .form-itm__value {
    width: 50%; }
  .form-ftr {
    width: 100%;
    margin-top: 3rem; }
  .form-ftr__top {
    width: 100%; }
  .form-ftr__bottom {
    margin-top: 3rem; }
  .recaptcha {
    width: 50%; }
  .form-confirm {
    margin-left: auto; }
  .content_vacancy {
    width: 100%; }
  .popup-vacancy__name {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto; }
  .icon_close-vacancy {
    right: calc( -50px/2 + 4%);
    top: 0; }
  .vacancy-left {
    padding: 4rem 0; }
  .vacancy-right {
    padding: 4rem 0;
    margin-left: 0; }
  .vacancy-detail {
    width: 90%;
    padding: 0;
    margin: 0 auto; }
  .popup-resume-content {
    padding: 0;
    padding-right: 1rem;
    width: 90%;
    margin: 0 auto; }
  .docs__lst {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1; }
  .first-screen-title {
    font-size: 3rem; }
  .prod .sidebar_inner {
    width: 40px; }
  .prod .title_inner {
    text-align: right;
    -webkit-transform: rotate(-90deg) translate(calc(-100% - 2rem));
    -ms-transform: rotate(-90deg) translate(calc(-100% - 2rem));
    transform: rotate(-90deg) translate(calc(-100% - 2rem)); }
  .prod-desc {
    width: 100%;
    margin: 1.5rem 0; }
    .prod-desc:first-child {
      margin-top: 0; }
  .prod-desc__content {
    width: calc(100% - 40px - 4.5rem); }
  .wrap_catalog {
    width: 80%; }
    .wrap_catalog.active {
      left: 20%; }
  .content_cat {
    margin: 0 10%; } }

@media (max-width: 948px) {
  .face-itm {
    height: 320px;
    width: calc(100%/2 - 15px); }
    .face-itm:nth-child(3n) {
      margin-right: 15px; }
    .face-itm:nth-child(3n-2) {
      margin-left: 15px; }
    .face-itm:nth-child(odd) {
      margin-left: 0; }
    .face-itm:nth-child(even) {
      margin-right: 0; }
  .prod-cat__itm {
    width: calc(100%/2 - 15px); }
    .prod-cat__itm:nth-child(3n) {
      margin-right: 15px; }
    .prod-cat__itm:nth-child(3n-2) {
      margin-left: 15px; }
    .prod-cat__itm:nth-child(odd) {
      margin-left: 0; }
    .prod-cat__itm:nth-child(even) {
      margin-right: 0; } }

@media (max-width: 830px) {
  .slideshow-block {
    height: 100%;
    max-width: 400px; }
  .slideshow-thumbs-block {
    min-width: 100px; } }

@media (min-width: 815px) {
  .hdr__inner .hdr__logo .logo__m {display: none !important  ;
    }
  }
   
@media (max-width: 815px) {
  .hdr__logo > span{display: none;
    }
  }
   

@media (max-width: 767px) {
   :root {
    --hdr: 60px; }
  .activity,
  .lang-current .hdr-nav__txt,
  .lang__txt {
    display: none; }
  .icon_down {
    margin-left: .25em; }
  .lang__itm {
    margin: 0; }
  .icon_flag {
    margin: 0;
    width: 25px;
    height: 25px; }
  .hdr.hdr_mainpage .hdr__logo {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .hdr.hdr_mainpage .logo__b {
    width: 150px; }
  .hdr.hdr_mainpage .logo__m {
    margin-left: 0; }
  .hdr.hdr_mainpage .hdr-nav {
    margin-left: 0;
    width: 100%; }
  .hdr.hdr_mainpage .hdr-nav__lst {
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .hdr.hdr_mainpage .lang {
    margin-left: 0; }
  .logo__b {
    width: 80px; }
  .logo__m {
    width: 90px;
    margin-left: .5rem; }
  .sidebar_menu {
    display: none; }
  .content_menu-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    padding: calc(var(--hdr) + 15rem) 0 2rem; }
  .menu {
    padding-bottom: 0; }
  .menu__lst {
    width: 100%;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    left: 0; }
    .menu__lst.active .menu__itm {
      display: none; }
      .menu__lst.active .menu__itm.active {
        display: block; }
        .menu__lst.active .menu__itm.active .menu__txt {
          display: none; }
        .menu__lst.active .menu__itm.active .icon_menu {
          -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
    .menu__lst.active .submenu {
      left: 10%; }
  .submenu {
    width: 80%; }
  .main .hero__itm-info {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    padding-bottom: 5rem; }
  .main .title {
    display: block;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    width: 90%;
    margin: 0 auto; }
  .main .title_inner {
    font-size: 3rem; }
  .sidebar_hero {
    display: none; }
  .hero__title-addon {
    width: 90%;
    height: 5ch;
    overflow: hidden;
    display: block; }
  .hero__itm-info {
    width: 90%;
    margin-left: 0; }
  .hero__dots-lst {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .sidebar {
    width: 100%;
    height: auto;
    min-height: unset; }
  .title {
    padding: .5rem;
    position: relative;
    top: unset;
    left: unset;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    font-size: 3rem; }
  .inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding-top: var(--hdr); }
  .s-category .content {
    width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0; }
  .s-category .content_r {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap; }
  .s-category .sidebar {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none; }
  .category__lst {
    height: 100%;
    padding: 0; }
    .category__lst:hover .category__itm {
      height: calc(400%/30);
      width: 100%; }
      .category__lst:hover .category__itm:hover {
        height: calc(200%/6);
        width: 100%; }
  /*.category__itm {
    height: calc(100%/6);
    width: 100%; }*/
  .category__img {
    -o-object-position: center center;
    object-position: center center; }
  .category__info {
    margin: 0;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .content_about,
  .mpage-news {
    padding-top: var(--hdr);
    width: 90%;
    margin: 0 auto; }
    .content_about .title,
    .mpage-news .title {
      width: 100%; }
  .mpage-about-inner {
    width: 100%;
    padding: 0; }
  .wrap_slider {
    width: 100%;
    margin-left: 0;
    height: 200px;
    padding: 0; }
  .wrap_news {
    width: 100%;
    padding: 0; }
  .news__lst {
    display: inline-block; }
  .news__itm {
    display: inline-block;
    width: 100%;
    padding: 0; }
  .ftr__lnks-itm {
    width: 100%;
    max-width: 100%;
    margin: 0; }
    .ftr__lnks-itm.active .ftr__lnk-lst {
      height: 100%;
      opacity: 1; }
    .ftr__lnks-itm.active .ftr__title::before {
      width: 100%; }
  .ftr__title {
    padding: .5rem 0;
    margin: 0;
    display: inline-block;
    cursor: pointer; }
    .ftr__title::before {
      content: "";
      position: absolute;
      height: 1px;
      width: 0;
      left: 0;
      bottom: -.25rem;
      background-color: currentColor;
      -webkit-transition: all 0.2s ease-in-out 0s;
      -o-transition: all 0.2s ease-in-out 0s;
      transition: all 0.2s ease-in-out 0s; }
  .ftr__lnk-lst {
    height: 0;
    opacity: 0; }
  .ftr__logo-itm {
    width: auto;
    max-width: 30%; }
  .ftr__copyright {
    font-size: 1rem; }
  .else .first-screen .sidebar {
    position: absolute;
    z-index: 2;
    top: calc(var(--hdr) + 1rem); }
  .else .first-screen .inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap; }
  .else .first-screen .title {
    width: 90%;
    margin: 0 auto;
    display: block;
    z-index: 1; }
  .else .first-screen-inner {
    padding: 10rem 0 2rem; }
  .else .first-screen-pic {
    height: 100%;
    top: 0; }
  .else .first-screen-addon {
    margin-top: .5rem; }
  .back,
  .breadcrumbs__txt {
    font-size: 1.6rem; }
  .icon_breadcrumbs {
    width: 25px; }
  .prod .first-screen {
    height: auto;
    background: #fff; }
  .prod .first-screen-content {
    width: 90%;
    margin: 0 auto; }
  .prod .sidebar_inner {
    width: 100%; }
  .prod .title_inner {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    text-align: left; }
  .first-screen-content {
    width: 100%;
    padding: 0; }
  .first-screen-inner_prod {
    width: 100%;
    padding: 1.5rem 0; }
  .slideshow-block {
    width: 90%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    max-width: unset;
    margin: 0 auto; }
  .slideshow-disp {
    height: 200px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 100%;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%; }
    .slideshow-disp.slick-slider {
      width: 100%; }
    .slideshow-disp .slick-list {
      width: 100%; }
    .slideshow-disp .slick-slide {
      width: 100%; }
  .slideshow-disp__itm {
    height: auto;
    width: 100%;
    padding: 0; }
  .breadcrumbs_prod {
    margin-top: 0; }
  .slideshow-thumbs-block {
    width: 100%;
    height: 100px;
    margin: 0; }
  .slideshow-thumbs {
    height: 100%;
    display: inline-block; }
    .slideshow-thumbs .slick-slide {
      height: 100%; }
  .slideshow-thumbs__itm {
    width: 30%;
    padding: 0; }
  .slideshow-thumbs__ctrls {
    width: 30px;
    height: 50px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .slideshow-thumbs_prev {
    left: -15px; }
    .slideshow-thumbs_prev .icon_slideshow-thumbs {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg); }
  .slideshow-thumbs_next {
    left: calc(100% - 15px);
    bottom: unset; }
    .slideshow-thumbs_next .icon_slideshow-thumbs {
      -webkit-transform: none;
      -ms-transform: none;
      transform: none; }
  .prod-desc {
    width: 100%;
    min-height: unset;
    margin: 1.5rem 0; }
  .prod-desc__content {
    width: 100%; }
  .first-screen .inner {
    width: 100%; }
  .content_faces {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    padding: 5rem 0 2rem 0; }
  .icon_close-faces {
    top: 0; }
  .faces__left {
    height: auto;
    width: 100%; }
  .face__pic {
    height: 200px; }
  .face__position {
    position: absolute;
    bottom: 0;
    font-size: 1.8rem; }
  .faces__right {
    margin-left: 0;
    margin-top: 1rem;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    height: calc(100% - 200px);
    overflow: auto;
    overflow-y: scroll; }
    .faces__right::-webkit-scrollbar {
      width: 6px; }
    .faces__right::-webkit-scrollbar-track {
      background: transparent; }
    .faces__right::-webkit-scrollbar-thumb {
      background: #66cccc;
      border-radius: 3px; }
  .face__name {
    font-size: 2.5rem; }
  .face__info-lst {
    width: 100%;
    margin-top: 1.5rem; }
  .face__info-title {
    font-size: 1.6rem;
    margin-bottom: .5rem; }
  .face__info-value {
    line-height: 1.2;
    font-size: 1.6rem; }
  .face__info-born {
    font-size: 2rem; }
  .face__workexp {
    margin-top: 1.5rem; }
  .face__workexp-title {
    font-size: 1.8rem; }
  .face__workexp-lst {
    overflow: auto;
    height: auto; }
  .face-work {
    padding: 1rem 0 1rem calc(2rem + 25px); }
    .face-work:hover .face-work__dates::before {
      left: calc(-2rem - 24.5px); }
  .face-work__dates {
    font-size: 2rem; }
    .face-work__dates::before {
      left: calc(-2rem - 19px); }
  .face-work__exp {
    font-size: 1.6em;
    text-transform: none;
    line-height: 1.2;
    letter-spacing: 0; }
  .vacancy-section {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1; }
  .form-itm {
    padding: 1rem 0; }
  .form-itm__title {
    width: 100%;
    padding-bottom: .5rem;
    font-size: 1.8rem;
    letter-spacing: 0; }
  .form-itm__value {
    width: 100%; }
  .btn {
    font-size: 1.8rem; }
  .recaptcha {
    width: 100%; }
  .form-confirm {
    margin: 1rem auto;
    width: auto; }
  .icon_close-vacancy {
    right: 0; }
  .content_vacancy {
    height: 100%;
    overflow: auto;
    overflow-y: scroll;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap; }
    .content_vacancy::-webkit-scrollbar {
      width: 6px; }
    .content_vacancy::-webkit-scrollbar-track {
      background: transparent; }
    .content_vacancy::-webkit-scrollbar-thumb {
      background: #66cccc;
      border-radius: 3px; }
  .popup-resume-content {
    overflow: auto;
    height: auto; }
    .popup-resume-content .form-itm__title,
    .popup-resume-content .form-itm__value,
    .popup-resume-content .recaptcha {
      width: 100%; }
    .popup-resume-content .form-confirm {
      width: auto;
      margin: 1rem auto; }
  .vacancy-left {
    height: auto;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    padding: 4rem 0 2rem; }
  .popup-vacancy__name {
    font-size: 2.5rem; }
  .vacancy-summary {
    margin-bottom: 0; }
  .vacancy-summary__name,
  .vacancy-summary__value {
    font-size: 1.8rem; }
  .vacancy-right {
    padding: 2rem 0; }
  .btn_resume {
    width: auto;
    margin: 1rem auto; }
  .vacancy-detail {
    height: auto;
    overflow: auto; }
  .docs__lnk {
    font-size: 1.8rem; }
  .docs__name {
    text-transform: none; }
  .first-screen-info {
    width: 100%; }
  .first-screen-title {
    font-size: 2rem; }
  .first-screen-tag {
    display: none; }
  .newsitm-block,
  .article {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1; }
    .newsitm-block *,
    .article * {
      font-size: 1.6rem;
      line-height: 1.2; }
    .newsitm-block h2,
    .newsitm-block footer,
    .newsitm-block figcaption,
    .article h2,
    .article footer,
    .article figcaption {
      font-size: 1.8rem;
      line-height: 1.2; }
    .newsitm-block img,
    .article img {
      height: 200px; }
  .news-block__bottom {
    margin-top: 7rem; }
  .news-ctrls {
    width: 100%; }
  .news__lst_newslstpage .news__itm {
    width: 100%; }
  .first-screen-contacts {
    display: none; }
  .contact-place {
    width: 100%; }
  .contact-place__title,
  .contact-info__lnk,
  .contact-place__spec,
  .hotline__title,
  .hotline__txt,
  .history-hdr__date,
  .history-title {
    font-size: 1.8rem; }
  .hotline__info {
    width: 100%; }
  .logo__hotline {
    margin-left: auto;
    height: 50px; }
  .form-hdr {
    width: 100%;
    margin-bottom: 3rem;
    padding-bottom: 1rem; }
  .feedback-content {
    width: 100%; }
  .history-inner {
    height: auto; }
  .history-itm {
    height: auto; }
    .history-itm:nth-child(even) .history-left {
      -webkit-box-ordinal-group: 1;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0; }
  .history-left {
    width: 100%;
    height: auto; }
    .history-left::after, .history-left::before {
      display: none; }
  .history-date {
    font-size: 5rem; }
  .history-pic {
    height: 200px; }
  .history-right {
    width: 100%;
    left: 0;
    background: none;
    position: relative; }
    .history-right::before {
      background: none; }
  .history-bg {
    display: none; }
  .history-article {
    width: 90%;
    height: auto;
    padding: 2rem 0; }
  .history-hdr {
    margin-bottom: 2rem; }
  .history-content {
    font-size: 1.6rem;
    line-height: 1.2; }
  .first-screen-lst {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap; }
  .first-screen-itm {
    margin: 0; }
  .first-screen-lnk {
    font-size: 1.6rem;
    line-height: 1.2; }
  .wrap_catalog {
    width: 100%; }
    .wrap_catalog.active {
      left: 0; }
  .content_cat {
    margin: 0 5%; } }

@media (max-width: 630px) {
  .face-itm {
    width: 100%; }
    .face-itm:nth-child(odd) {
      margin-right: 0; }
    .face-itm:nth-child(even) {
      margin-left: 0; }
  .prod-cat__itm {
    width: 100%; }
    .prod-cat__itm:nth-child(odd) {
      margin-right: 0; }
    .prod-cat__itm:nth-child(even) {
      margin-left: 0; } }


@media (max-width: 499px) {
  .lidesktop {display: none !important; }
}
@media (min-width: 500px) {
  .limovil {display: none !important; }
}
 



.article ul *,
.article a {
  font-size: 1.6rem;
  line-height: 1.2;
}

.article ul li {
  margin-left: 18px;
  list-style: circle;
}

.article ul,
.article p {
    -webkit-column-break-inside: avoid;
            page-break-inside: avoid; /* Makes effect only in Firefox */
                 break-inside: avoid; /* IE10+, Opera 11.1—12.1 */
}

.contact-places {
  flex-direction: column;
}

.slideshow-block {
  height: 100%;
}

.slideshow-disp {
  max-height: 340px;
  height: 100%;
}

.hero__title-img_action {width: 75%!important}

.submenu {
  visibility: hidden;
}

.menu__itm:hover .submenu,
.menu__itm.active .submenu {
  visibility: visible;  
}
.down {
 display: none !important;
}


 
 
 
/*******  carrusel nuevo productos ***********/
  #carousel .row_flex_col .buy {     
    border-radius: 3px;background: #000;color: #fff;font-size: 12px;padding: 10px; 
    letter-spacing: 1px;text-align: center;display: block;margin: 0px auto;border: 1px solid #767676;
    height: auto; font-weight: 700;text-decoration: none; text-transform: uppercase;}


  #carousel .row_flex_col .buy:hover{
    background-color: #F0ECE3;color: #767676;border: 1px solid #767676 }
/**** CARRUSEL HOME BAYKAL *******/

  .foter_subt{padding: 5px !important}
  .itemProduct ul li {  position: relative;}
  .itemProduct ul li .contentFlag .num {
    position: absolute; top: 15px;  left: 15px;background: #fe0000;color: white;  
    width: 91px;  height: 27px; text-align: center;  align-items: center;
    font-size: 13px;  font-weight: 600;  border-radius: 3px; padding: 4px 0;}
  .itemProduct ul li .contentFlag .num:after {  content: " dscto";}
  .itemProduct ul li .row.img{
    text-align: center;margin: 10px; box-shadow:5px 00px 10px #000000;
    -moz-box-shadow:5px 0px 10px #000000; -webkit-box-shadow:5px 0px 10px #000000;}
  .itemProduct ul li .row.img img { width: 100%; height: auto;}
  .itemProduct ul li .row.img + div {  padding: 0 10px;}
  .itemProduct ul li .sku, .itemProduct ul li .brand { display: none;}
  .itemProduct ul li h2.productNames {
    margin: 0; font-size: 16px;line-height: 1;padding-bottom: 1px solid #EAEAEA;
    padding-bottom: 13px;}
  .itemProduct ul li h2.productNames2 {font-weight: bold;color: #29323c; display: flex;justify-content: center;align-items: center;
    margin: 0;font-size: 16px;line-height: 1;padding-bottom: 1px solid #EAEAEA;
    padding-bottom: 4px;text-align: center;min-height: 50px;}
  .itemProduct ul li h2.productNames2 a{    text-decoration: none;    }
  .itemProduct ul li h2.productNames2 a:hover {color:#fe0000 }

  .itemProduct ul li h2.productName {
    margin: 0;font-size: 16px;line-height: 1;padding-bottom: 1px solid #EAEAEA;
    padding-bottom: 13px;}
  .itemProduct ul li .precio {
    margin: 0;   border-top: 1px solid #EAEAEA; min-height: 63px;}
  .itemProduct ul li .precio .antes {
    font-size: 12px; color: rgba(0, 0, 0, 0.5);text-decoration: line-through;}
  .itemProduct ul li .precio .normal, .itemProduct ul li .precio .ahora {
    font-size: 16px; color: #146ca4;  font-weight: 600; display: block;text-shadow: 1px 1px 1px #460B09}
  .itemProduct ul li .precio :nth-child(2){background-color: #29BB32;color: #fff;padding: 5px;border-top-right-radius: 5px;
    border-top-left-radius: 5px;font-weight: 500;float: right;font-size: .7rem  }
    .itemProduct ul li .precio :nth-child(3){float: left;}
  .itemProduct ul li .precio :nth-child(3) img{    padding: 0px !important;width: 40%;margin: 0px auto  }  

  .itemProduct ul li .row.btn-acc { margin-top: 0px;width: 100%; padding-left: 25px}
  .itemProduct ul li .row.btn-acc .buy {     
    border-radius: 3px;background: #146ca4;color: #fff;font-size: 12px;padding: 10px; 
    letter-spacing: 1px;text-align: center;display: block;margin: 0px auto;border: 1px solid #767676;
    height: auto; font-weight: 700;text-decoration: none; text-transform: uppercase;}
   .itemProduct ul li .row.btn-acc .buy:hover{
    background-color: #F0ECE3;color: #767676;border: 1px solid #767676 }

    /*** Explora Productos ****/
  .explora_productos { position: relative;}
  .explora_productos .owl-explora-productos {  width: 90%;  margin: 0 auto;}
  .explora_productos .owl-explora-productos .owl-nav {
    position: absolute;top: 50%;transform: translateY(-50%);width: 100%;}
  .explora_productos .owl-explora-productos .owl-nav .owl-prev {
    float: left; margin-left: -36px;width: 33px;height: 33px;transform: rotate(0deg);
    background-image: url("../../public/img/prueva/flecha_slider_izquierda2.png");
    background-repeat: no-repeat;background-size: cover;background-position: center;}
  .explora_productos .owl-explora-productos .owl-nav .owl-prev span {  display: none;}
  .explora_productos .owl-explora-productos .owl-nav .owl-next {
    float: right; margin-right: -36px;width: 33px; height: 33px;
    background-image: url("../../public/img/prueva/flecha_slider_derecha2.png");
    background-repeat: no-repeat;background-size: cover;background-position: center;
    transform: rotate(0deg);}
  .explora_productos .owl-explora-productos .owl-nav .owl-next span { display: none;}
  .explora_productos .owl-explora-productos .owl-nav button {  background-color: transparent;}
  .explora_productos .itemProduct > h2 {  display: none;}
  .explora_productos .itemProduct > h2 img {  width: auto;}
  .explora_productos .itemProduct .owl-stage {  margin: 0 auto;}
  .explora_productos .itemProduct .owl-stage img {  height: auto;}
   
  .consult{
  	background-color: gold !important;color: #9b9b9b  !important;border: 1px solid #9b9b9b 
  }


/******** CARRUSEL INICIAL ESTYLO SANSUNG *******/
   .ofer_ h2{ font-size: 2.8rem;color: #fff;text-shadow: 2px 2px 2px #460B09;text-align: center;
  margin: 10px; }
  #sync2{ display: flex;justify-content: space-between;align-items: center;flex-wrap: wrap;
    width: 100%;}

   .tab__item{   text-align: center;  
            background-color: #435c39;box-shadow: inset 0 -5px 5px -5px #333,
              inset -5px 0 5px -5px #333, 
              inset 5px 0 5px -5px #333;;border-radius: 2px;}
  #sync2  .tab__item-title{  
  display:-webkit-inline-box;display:-ms-inline-flexbox;
  display:inline-flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;
  flex-direction:column;position:relative;font-size:18px;color:#fff;line-height:24px;
  padding-top:4px;padding-bottom:4px; }
 
  #sync2   .tab__item-line{color: #b9d87c !important;
  width:100%;height:2px;  background-color:#b9d87c;  -webkit-transform-origin:50% 50% 0; transform-origin:50% 50% 0;-webkit-transform:scaleX(0);transform:scaleX(0);
  -webkit-transition:-webkit-transform .2s cubic-bezier(0.4,0,0.2,1); transition:-webkit-transform .2s cubic-bezier(0.4,0,0.2,1);
  transition:transform .2s cubic-bezier(0.4,0,0.2,1); transition:transform .2s cubic-bezier(0.4,0,0.2,1),-webkit-transform .2s cubic-bezier(0.4,0,0.2,1)}
  .tab__item--active .tab__item-line{-webkit-transform:scaleX(1);transform:scaleX(1)}
  #sync1 .item {  color: #000;   text-align: center;}
  #sync2 .item {    margin: 5px;  color: #fff;  text-align: center;  cursor: pointer;
  border-radius: 5px; }
  #sync2   button{    background-color: #435c39 ;font-weight: bold;font-size: 1.4rem !important;
    border-style:none ;   } 
  #sync2   button:hover{    background-color: rgba(0, 0, 0, 0.5);    } 
  #sync2 .current  span{-webkit-transform:scaleX(1);transform:scaleX(1)}
  #sync2 .current  button:focus{   border-style: dotted !important  ; border-color: #b9d87c !important;}
  .banner_ofert img{width: 100%;height: 95%;}
  .row_flex{  display: flex;justify-content: center;align-items: center; }
  .row_flex .row_flex_col img{  width: 450px}
  .row_flex .row_flex_col h3{  font-family: 'Montserrat', sans-serif;font-weight: bold;
  color: #b9d87c;font-size: 3rem;text-shadow: 1px 1px 2px #460B09}
  .row_flex_col{display: flex;justify-content: center;align-items: center;flex-direction: column;}
  .row_flex_col:hover .gal-img{ 
    -webkit-transform: scale(1.05); -ms-transform: scale(1.05); transform: scale(1.05);} 
  .row_flex:hover .gal-imgban{
    -webkit-transform: scale(1.05); -ms-transform: scale(1.05); transform: scale(1.05);} 
  .row_flex_col{   cursor: pointer;}
  .gal-imgban,.gal-img{ -webkit-transition: all 0.5s; transition: all 0.5s;  } 
  #sync1 .item h3{font-family: 'Montserrat', sans-serif;font-weight: bold;
  color: #b9d87c;font-size: 4rem;text-shadow: 1px 1px 2px #460B09;
  font-weight: bold;  display: flex;justify-content: center;align-items: center;
    margin: 0;font-size: 16px; text-align: center;min-height: 50px;}
  #sync1 ._cont_ .row_flex .row_flex_col a {
    position: absolute;    bottom: 1px;     margin: 0;    padding: 0;    list-style: none;
    opacity: 0;border-radius: 3px;background: #29323c;color: #fff;font-size: 12px;padding: 10px; 
    letter-spacing: 1px;text-align: center;display: block;margin: 0px auto;border: 1px solid #fe0000;
    height: auto; font-weight: 700;text-decoration: none; text-transform: uppercase;
    transition: bottom 0.5s, opacity 0.5s;}
  #sync1 ._cont_ .row_flex_col:hover   a {    bottom: 80px;    opacity: 1;}
  #sync1 ._cont_ .row_flex .row_flex_col a:hover{
    background-color: #F3F0D7;color: #000;border: 1px solid #000 }


/************* CONTACT ***********/
  .contactame section{ background-color: rgba(0, 0, 0, 0.6) }
  .contcotact h3{  margin-top: 10px; text-shadow: 2px 2px 2px #460B09;
        color: #fff;  font-size: 2.8rem;text-align: center;}
  .linea_blanco{background-color: rgb(255, 255, 255);}
  .sec_redessoci{display:flex ;flex-direction: row;justify-content: center;align-items: center; }
  .sec_redessoci ul li{display:inline; }
  .sec_redessoci ul li a:hover{opacity: .5; }
  .contac_vid p{text-shadow: 2px 2px 2px #460B09;
        color: #b9d87c !important; }
  .contac_trat ul li a{text-shadow: 2px 2px 2px #460B09;line-height: 2.9;
        color: #fff;font-family: 'Montserrat', sans-serif;}
  .contac_trat ul li a:hover{   color: #b9d87c;}
  .contac_trat i{font-size: 2.5rem;width: 35px;position: relative;}
  
  .contac_trat_red ul li a{text-shadow: 2px 2px 2px #460B09;line-height: 2.7;
        color: #fff;font-family: 'Montserrat', sans-serif;}
  .contac_trat_red ul li a:hover{   color: #b9d87c;}
  .contac_trat_red i{font-size: 2.5rem;width: 35px;position: relative;}
  .contac_noti{ padding-left: 60px;padding-right: 60px;}
  .contac_trat{padding-left: 40px;padding-right: 40px;}
  .contac_trat_red{padding-left: 30px;padding-right: 30px;}
  .r-title{
    margin-top: var(--rTitleMarginTop, 0) !important;
    margin-bottom: var(--rTitleMarginBottom, 0) !important;  }
  .time{  display: var(--timeDisplay, inline-flex);}
  .time__month{  margin-left: var(--timelineMounthMarginLeft, .25em);}
  .time{  padding: var(--timePadding, .25rem 1.25rem .25rem);
  background-color: var(--timeBackgroundColor, #f0f0f0);  font-size: var(--timeFontSize, 1.2rem);
  font-weight: var(--timeFontWeight, 700);  text-transform: var(--timeTextTransform, uppercase);
  color: var(--timeColor, currentColor);}
  .card{  padding: var(--timelineCardPadding, 1.5rem 1.5rem 1.25rem);}
  .card__content{font-family: 'Montserrat', sans-serif;color: #000;font-weight: bold;
  margin-top: var(--cardContentMarginTop, .5rem);font-size: 1.2rem;  }
  .card{  border-radius: var(--timelineCardBorderRadius, 2px);
  border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--timelineCardBorderLeftColor, var(--uiTimelineMainColor));
  box-shadow: var(--timelineCardBoxShadow, 0 1px 3px 0 rgba(0, 0, 0, .12), 0 1px 2px 0 rgba(0, 0, 0, .24));
  background-color: var(--timelineCardBackgroundColor, #fff);}
 .card__title{  --rTitleMarginTop: var(--cardTitleMarginTop, 1rem);
  font-size: var(--cardTitleFontSize, 1.25rem);}
  .timeline{  display: var(--timelineDisplay, grid);
  grid-row-gap: var(--timelineGroupsGap, 2rem);}
  .timeline__year{  margin-bottom: 1.25rem; /* 1 */}

  .timeline__cards{  display: var(--timeloneCardsDisplay, grid);
    grid-row-gap: var(--timeloneCardsGap, 1.5rem);}

  .timeline{  --uiTimelineMainColor: var(--timelineMainColor, #222);
    --uiTimelineSecondaryColor: var(--timelineSecondaryColor, #fff);
    border-left: var(--timelineLineWidth, 3px) solid var(--timelineLineBackgroundColor, var(--uiTimelineMainColor));
    padding-top: 1rem;  padding-bottom: 1.5rem;}

  .timeline__year{  --timePadding: var(--timelineYearPadding, .5rem 1.5rem);
    --timeColor: var(--uiTimelineSecondaryColor);
    --timeBackgroundColor: var(--uiTimelineMainColor);
    --timeFontWeight: var(--timelineYearFontWeight, 400);}

  .timeline__card{  position: relative !important;
    margin-left: var(--timelineCardLineGap, 1rem) !important;}

  .timeline__cards{  overflow: hidden;  padding-top: .25rem; /* 1 */
    padding-bottom: .25rem; /* 1 */}

  .timeline__card::before{  content: "" !important;  width: 100% !important;
    height: var(--timelineCardLineWidth, 2px) !important;
    background-color: var(--timelineCardLineBackgroundColor !important, var(--uiTimelineMainColor)) !important;
    position: absolute !important;  top: var(--timelineCardLineTop, 1rem) !important;
    left: -50% !important;  z-index: -1 !important;}
  .timeline{  --timelineMainColor: #435c39 !important;}




  /**** Noticia Inicial 01***/
  .card_noty1 {opacity: 0;}
  .card_noty1.card.visible_noty_1 { animation: slideleft_noty  .4s .3s   forwards}
  .card_noty2 {opacity: 0;}
  .card_noty2.card.visible_noty_2 { animation: slideleft_noty  .5s .5s   forwards}
 
  
  @keyframes slideleft_noty {
    0%{opacity: 1;  transform: translateX(-20px);}
    100%{opacity: 1;transform: translateX(0);}
  }
   


/*********** FOOTER ************/
  footer{background-color: #618045;border-top: 1px solid rgb(37, 150, 190);color: #fff}
  .footer-section {   position: relative;}
  .copyright-area { padding: 5px 0;}
  .copyright-text p {margin: 0;font-size: 14px;color: #fff;}
  .copyright-text p a { color: #fd0a43;}
  .footer-menu li { display: inline-block; margin-left: 20px;}
  .footer-menu li:hover a { color: #fd0a43;}
  .footer-menu li a { font-size: 14px;  color: #fff;}
 
/*********** TIENDA ************/
  .tiendaheder section {  border-bottom: 1px solid #17a2b8;      background-repeat: no-repeat; 
      background-attachment: fixed;  }
  .tiendaheder section  div{height: 210px;display: flex;justify-content: center;align-items: center;
    flex-direction: column;color: #b9d87c; padding-top: 40px !important}
   .tiendaheder section  div h1{ font-family: 'Montserrat', sans-serif;font-weight: bold;
   text-shadow: 1px 1px 2px #460B09; } 
   .tiendaheder .tit-tienda { background-color: rgba(0, 0, 0, 0.7);}
   .tit-tienda h1 t{word-spacing: 5px;text-transform: uppercase;font-size: 1.5rem; }
   .tit-tienda h1 a{text-decoration: none;}
   .heder_check_catg{position: relative;background-color: #FFF;border-bottom: 1px solid #17a2b8;
    border-top-left-radius: 10px;border-top-right-radius: 10px;padding: 10px;}
   .heder_check_catg h1{color:#b9d87c;text-shadow: 1px 1px 2px #460B09; 
    font-size: 1.3rem;  font-weight: bold;font-family: 'Montserrat', sans-serif; }
  .row_tienda { padding:20px 30px; }
  .section-products { padding: 10px 0px;}
  .section-products .single-product { margin-bottom: 26px; background-color: rgba(0, 0, 0, 0.6);}
  .section-products .single-product .part-img {position: relative; margin-bottom: 20px; }
  .section-products .single-product .part-img img{padding: 10px;border-top-right-radius: 6%;
    border-top-left-radius:6%;width: 100%;  }
  .section-products .single-product .part-img .discount,
  .section-products .single-product .part-img .new {
    position: absolute; top: 15px; left: 20px;color: #ffffff;        }
   #imgmarka{margin: 0px;padding: 0px;width: 55%;box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
   	border-right:1px solid #17a2b8; border-top:1px solid #17a2b8; border-bottom:1px solid #17a2b8;  	 }
  .section-products .single-product .part-img .new {  left: 0;   }
  .section-products .single-product .part-img ul {
    position: absolute;bottom: -41px;left: 20px;margin: 0;padding: 0;
    list-style: none; opacity: 0;transition: bottom 0.5s, opacity 0.5s;}
  .section-products .single-product:hover .part-img ul { bottom: 80px;opacity: 1;}
  .section-products .single-product .part-img ul li {
    display: inline-block;/*padding: 5px;*/margin-right: 4px;}
  .section-products .single-product .part-img ul li a {
    display: inline-block;text-shadow: 2px 2px 2px #460B09;transition: color 0.2s;
    width: 40px;height: 40px;line-height: 40px;background-color: #ffffff;
    color: #444444;text-align: center;box-shadow: 0 2px 20px rgb(50 50 50 / 10%);}
  .section-products .single-product .part-img ul li a:hover {color: #fe302f;}
  .section-products .single-product .part-title .product-title {color: #b9d87c;text-shadow: 1px 1px 2px #460B09; 
    font-size: 2.1rem;text-align: center;font-family: sans-serif;font-weight: 500;
    min-height: 40px;  }
   
  .single-product .car_adds {font-size: .8rem;
    border-radius: 3px;transition: .5s all;
    background: #445c3c;color: #b9d87c; border: 1px solid #b9d87c;width: 50%;
    padding: 10px; margin-top: 10px !important;z-index: 2;position: relative;
    letter-spacing: 1px;text-align: center;display: block;margin: 5px auto;text-shadow: 2px 2px 2px #460B09;
    height: auto; font-weight: 700;text-decoration: none; text-transform: uppercase;}
   .single-product .car_adds:hover{
    background-color: #445c3c;color: #b9d87c;border: 1px solid #000 }
  .product-price{color:#0a91ab;text-shadow: 1px 1px 2px #460B09;font-weight: bold !important;font-family: sans-serif;}

  #heart.red {
    color: red; -webkit-animation: latido .6s linear 6 alternate;
     animation: latido .6s linear 6 alternate;}
  /******** Buscar  X categoria ***********************/
  .check_catg {border-radius: 10px; border:1px solid #17a2b8; margin-top: 60px;background-color: rgba(0, 0, 0, 0.7); }
   
 
  .conpaginate{display: flex;text-align: center;flex-direction: column;justify-content: center;align-items: center;}
  .conpaginate p{color:#b9d87c;text-shadow: 1px 1px 2px #460B09;
    font-weight: bold;font-family: sans-serif;font-family: 'Montserrat', sans-serif;}
  

  /********* TIENDA galeria ******************/
  .pagination li {  margin-left: .25rem;  margin-right: .25rem;}
  .pagination li .page-link {text-align: center; color: #4f5464;
    border-radius: .25rem;  border: none;min-width: 2.25rem;    }
  .pagination li.active .page-link,
  .pagination li .page-link:hover {  background-color: #1d97c9; color: #fff; font-weight: bold;}
  .btn-holder::before,.btn-holder-btn::before,
  .btn-holder::after,.btn-holder-btn::after {
    position: absolute;  content: "";}
  .btn-holder {
  display: flex;  flex-wrap: wrap;  justify-content: center;
  max-width: 1000px;margin: 10px; }
  .btn-holder-btn {  position: relative;  display: inline-block;
  width: auto;  height: auto;  background-color: transparent;
  border: none;  cursor: pointer;   min-width:230px;}
  .btn-holder-btn span {
  position: relative;  display: inline-block;  font-size: 14px;
  font-weight: bold;  letter-spacing: 2px;  text-transform: capitalize;
  top: 0;  left: 0;  width: 100%;  padding: 10px 10px;  transition: 0.3s;}
   /*--- btn-2 ---*/
  .btn-2::before {  background-color: #DEDBDA;  transition: 0.3s ease-out;}
  .btn-2 span {
    font-family: 'Open Sans',Arial, Helvetica, sans-serif !important;color: #767676; 
    border: 1px solid #F0ECE3;  transition: 0.2s;}
  .btn-2 span:hover {  color: #146ca4;font-weight: bold; transition: 0.2s 0.1s;}
  /* 9. hover-slide-right */
  .btn-holder-btn.hover-slide-right::before {
    top: 0;  bottom: 0;  left: 0;  height: 100%;
    width: 0%;}
  .btn-holder-btn.hover-slide-right:hover::before {
    width: 100%;}

/********** SHOW CARD **********/
	.showcard{  padding-bottom: 50px}
  .showcard .titshows h1{text-align: center; margin: 0px auto;}
   .titshows h1{color: #b9d87c;text-shadow: 1px 1px 2px #460B09;text-align: center;}

  .titshows h1 a:hover{color:#fff ;}
 	.show_gale {    margin: 25px; 
	 	position: relative;border-radius: 20px;
		border: 1px solid #445c3c;margin: 0px auto; 
	    -webkit-box-shadow:2px 2px 5px #b9d87c;border-radius: 20px;
	    box-shadow:2px 2px 5px #b9d87c;-moz-box-shadow:2px 2px 5px #b9d87c;}
	.show_gale .pannel_imgpro { display: flex;justify-content: center;align-items: center;
	    text-align: center; padding: 20px; }
	.show_gale .pannel_imgpro figure{width: 70%;border-radius: 2%} 
	.show_gale .pannel_imgpro .inside{display: flex;justify-content: center;align-items: center;}
	.pannel_descrip{
		 border-top-right-radius: 20px;border-bottom-right-radius: 20px }
	.pannel_descrip_cont {     margin-top: 25px;margin-left: 10px;color: #62605f}
	.pannel_descrip_cont .pannel_descrip_cont_tit  h3 {font-weight: 550;
	    font-size: 23px; color:#b9d87c ;text-shadow: 1px 1px 2px #460B09;}
    .pannel_descrip_cont_desc {
    font-size: 14px;  font-family: 'Montserrat', sans-serif;
                   text-shadow: 1px 1px 2px #460B09; color: #b9d87c !important;
                   padding: 8px 20px}
  .pannel_descrip_cont .pannel_descrip_cont_desc span{float: right;top: 0; right: 0; display: flex;justify-content: flex-end;}    
  .pannel_descrip_cont .pannel_descrip_cont_desc a{text-decoration: none;position: absolute;z-index: 5;bottom: -5px;color: #b9d87c;}    
  .pannel_descrip_cont .pannel_descrip_cont_desc a h5{color:#445c3c;text-shadow: 1px 1px 2px #460B09;font-weight: 700;}    
  .pannel_descrip_cont .pannel_descrip_cont_desc a h5:hover{color:#b9d87c;}    
 	.pannel_descrip_cont .pannel_descrip_cont_desc a h5,.pannel_descrip_cont .pannel_descrip_cont_desc p {
	     margin-top: 20px;	    font-size: 14px;}
       .pannel_descrip_cont_desc > p{line-height: 1.4   !important;}
  .fa-star{color:#b9d87c ;text-shadow: 1px 1px 2px #460B09;font-weight: 700;}     
	.pannel_descrip_cont .pannel_descrip_cont_desc span img{
		border: 1px solid rgba(0, 0, 0, 0.2);
		box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);width: 65%}
	.det_ti_pro { margin-top: 10px; margin-left: 10px;margin: 0px auto;display: block;}
  
	.btnshowcont{margin: 0px auto;display: block;text-align: center;}
	.btnshowcont .add-to-cart,.btnshowcont .ancla-cart {
		display: inline;border-radius: 3px;background: #29323c;color: #fff;font-size: 12px;padding:8px 2px; 
	     text-align: center; border: 1px solid #fe0000;transition: 1s all;
	    height: auto; font-weight: 700;text-decoration: none; text-transform: uppercase; }
	.btnshowcont .add-to-cart:hover,.btnshowcont .ancla-cart:hover{ background-color: #F3F0D7;color: #000;border: 1px solid #000 } 

	.product-count {position: relative;left: 0%;height: 30px;}
 	.button-count {display: inline-block;width: 30px; height: 30px;background-color: #21c999;
  				  color: #fff; font-size: 24px; line-height: 30px; text-align: center;
  				  border: none; outline: none;}
	.button-count:active { background-color: #21c999; }
	.number-product {display: inline-block;  width: 46px;  height: 28px;  font-size: 24px;
  	border: 1px solid silver;  text-align: center;}
	:disabled {  background-color: silver;}
	#alerts{font-weight: bold;color: #21c999;text-shadow: 1px 1px 1px #460B09;
	  animation: jump 0.75s;animation-iteration-count: infinite; }
  .inside_img_prev img{cursor: pointer;opacity: .9} 

  .inside_img_prev img:hover{opacity: 1}
  .caret_show i{color: #fe0000;font-weight: bold;text-shadow: 1px 1px 1px #460B09;cursor: pointer;}
  .fancybox-caption__body{font-family: 'Cinzel', serif;
    line-height: 0.8;  color: #b9d87c;font-size: 3rem}
  /*********  SHOWCARD EFECTO +***********/
  	figure.zoom {
  	   background-position: 50% 50%;  position: relative;   width: 500px; 
  	  overflow: hidden;   cursor: zoom-in;}
  	figure.zoom img:hover {  opacity: 0;}
  	figure.zoom img {
  	  transition: opacity 1s;   display: block;    width: 100%; }
  	.salirshow{position: absolute;left: 2%;top: 2%;color: #618045;font-size: 4rem;z-index: 51} 

/*****CORRUSEL PROVEDORES*******/
    .carrul{background-color: #618045;  }
    .carrul  h2{ font-size: 2.8rem;color: #fff;
    text-shadow: 2px 2px 2px #460B09;text-align: center;}
    .owl-carousel .owl-nav .owl-prev,
    .owl-carousel .owl-nav .owl-next  {width:50px; height:50px; }
    .owl-carousel .owl-nav {position: absolute;top: 40%; transform: translateY(-50%);width: 100%;}
    .owl-carousel .owl-nav button.owl-prev{float: right;   
        background: url('../../public/img/carrusel/flecha_estilo.png') no-repeat;}
    .owl-carousel .owl-nav button.owl-next{float: left;margin-left: 5px;
        background: url('../../public/img/carrusel/flecha_slider.png') no-repeat;}
    .contentInner .item_published  {text-shadow:2px 2px 2px #460B09} 
    .item_img img{width: 80% !important}

/********* VIDEOS **************/
  .cont_vid{  display: flex;  flex-wrap: wrap;width: 100%;  min-height: 100vh;justify-content: center;}
  .cont_vid .box{ position: relative; width: 50%;height: 50vh;filter: grayscale(1);border:4px solid #000;}
  .cont_vid .box:hover{filter: grayscale(0);} 
  .cont_vid .box video{position: absolute;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;}
  .cont_video h1{font-family: 'Montserrat', sans-serif;font-weight: bold;
   text-shadow: 1px 1px 2px #460B09; color: #fff}

/****SOMOS BULLET **************/
  .somos_bullet h1{font-family: 'Montserrat', sans-serif;font-weight: bold;
  color: #b9d87c;font-size: 4rem;text-shadow: 1px 1px 2px #460B09}
  .somos_bullet p{font-family: 'Montserrat', sans-serif;
   text-shadow: 1px 1px 2px #460B09;font-size: 2rem;color: #ffff;
   background-color: rgba(0, 0, 0, 0.6);padding: 20px;}
  
  .somos_bullet a p{color: #b9d87c;text-decoration: none;}
  .somos_bullet a p:hover{color: #fd0a43;text-decoration: none;}
  /*** envio fomra*/

  .somos_bullet h2{font-family: 'Montserrat', sans-serif;font-weight: bold;
  color: #b9d87c;font-size: 3rem;text-shadow: 1px 1px 2px #460B09}
  .somos_bullet span{font-family: 'Montserrat', sans-serif;font-weight: bold;
  color: #b9d87c;font-size: 3rem;text-shadow: 1px 1px 2px #460B09}


/**** CURSOS BANNER ***********/
 .cursos_ban_bullet{  display: flex;justify-content: center;align-items: center;flex-direction: column;}
 .cursos_ban_bullet p{color: #b9d87c;font-size: 2rem;text-shadow:2px 2px 2px #460B09

 }


/***** NOTICIAS ****************/
  .news-card {  background: #fff;  color: #000;}
  .notic_home{display: flex;  -ms-flex-wrap: wrap;  flex-wrap: wrap;
    margin-right: -15px;  margin-left: -15px;}
  .news {  padding: 30px;  margin-bottom: 32px;  border-radius: 5px;
    -webkit-box-shadow: 0 10px 20px rgb(5 66 125 / 8%);
    box-shadow: 0 10px 20px rgb(5 66 125 / 8%);  background-color: #fff;
    margin-left: 15px;  margin-right: 15px; }
  .card-img-top {
    width: 100%;  border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);}

  .news-image img {  box-shadow: 0px 0px 1px 1px #0000001a;
    animation: pulse-animation 1s infinite;}
  @keyframes pulse-animation {  0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.4);  }
    100% {    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);  }
  }

  .news-title h3 {  font-size: 23px;  margin-bottom: 6px;}
  .new-title span {  font-size: 10px;}
  .news-meta {  padding: 1px 15px;  font-size: 13px;  position: absolute; line-height: 1.8;
    background: -webkit-linear-gradient(right, #687c60, #819d5c 0.01%, #98b66a);
    width: fit-content;  color: #fff;  min-width: 65px;  right: unset;  top: 7%;}

  .news:hover {  --card-gradient: rgba(0, 0, 0, 0.8);  --card-gradient: #435c39, #98b66a;
    --card-blend-mode: overlay;  background-color: #fff;  border-radius: 0.5rem;
    box-shadow: 0.05rem 0.1rem 0.3rem -0.03rem rgb(0 0 0 / 45%);
     background-image: linear-gradient(    var(--card-gradient),    white max(9.5rem, 27vh)
    );
    overflow: hidden;}

/**** VALIDACION DE ALERTAS ****/
  input.success,textarea.success,select.success{  border:1px solid #008000 ; background-color: #BDE5AD;color: #000}
  label.invalid{  color: #DA0A13 !important; font-family: 'Caveat', cursive;  
  font-size: .8rem  ;position: absolute;top: 0  ;left: 0;background-color: #000}
  input.invalid,textarea.invalid, select.invalid{
    box-shadow: inset 0 1px 4px rgba(255, 0, 0, 0.6), 0 0 4px rgba(255, 0, 0, 0.6);background-color: #F3A7B8}
    /** alerta -***/
  .ajs-header,.ajs-button{  font-family: 'Kaushan Script', cursive !important;text-shadow: 2px 2px 2px #460b09 !important;}
  .ajs-error{ background-color: #FE1A00!important;font-family: 'Kaushan Script', cursive !important;color: #fff !important}
  .ulerror{  color: #FE1A00;  } 
  .ajs-header{ color: #fff!important;background-color: #FE1A00 !important;}
  .ajs-button{ color: #fff!important;background-color: #FE1A00!important}
  .ajs-success{color: #fff !important}
  .swal2-confirm{background-color: #0a91ab !important}
  .erorestyl{position: absolute;margin-top: -20px ;font-size: .9rem}
  .eror{background-color: #000;}
  #select-project-error{z-index: 2;top:90%;}
  #select-level-error{z-index: 2;top:90%;}
  #selectipodoc-error{z-index: 2;top:-90%;left: 0;font-size: .8rem}
  #checkterm-error{z-index: 2;top:-60%;left: 0;font-size: .8rem}
  .select2{ margin-top: 2px !important}
  #selectipodocd-error{z-index: 2;top:0%;}
 /******** FORMAS DE PAGO *********/
  .contformpago h1{font-family: 'Montserrat', sans-serif;font-weight: bold;
  color: #0a91ab;font-size: 1.5rem}
  .contformpago p{font-family: 'Open Sans',Arial, Helvetica, sans-serif !important;
  color: #767676}
  .contformpago a{color: #0a91ab;text-decoration: none;}
  /*** envio fomra*/
  .contformpago h2{font-family: 'Montserrat', sans-serif;font-weight: bold;
  color: #0a91ab;font-size: 1rem}

 
/**** BTN FLOTANTE WHSATPWEB *****/
  @keyframes pulsing {
    to {    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);  }
  }
  .hidden {  display: none;}

  .sticky-button-derma {
  position: fixed;  background-color: #25D366;  bottom: 20px;
  left: 20px;border-radius: 50px;box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
  z-index: 20;overflow: hidden;display: flex;align-items: center;
  justify-content: center;width: 75px;height: 75px;
  -webkit-transition: all 0.2s ease-out;transition: all 0.2s ease-out;
  transform: translatey(0px); animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 #42db87; }

  .sticky-button-derma svg {
  margin: auto;fill: #fff;width: 55px; height: 55px;}

  .sticky-button-derma a,.sticky-button-derma label {
  cursor: pointer;  display: flex;  align-items: center;
  width: 75px;  height: 75px;  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;}

  .sticky-button-derma label svg.close-icon {
  display: none;}

  .sticky-chat {
  position: fixed;bottom: 70px;
  left: 20px;width: 320px;-webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;z-index: 1000;
  opacity: 0;visibility: hidden;}

  .sticky-chat a {
  text-decoration: none;font-family: 'Roboto',sans-serif;
  color: #505050;}

  .sticky-chat svg {  width: 55px;  height: 55px;}

  .sticky-chat .chat-content {
  border-radius: 10px;  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
  overflow: hidden;  font-family: 'Roboto',sans-serif;
  font-weight: 400;}

  .sticky-chat .chat-header {
  position: relative;  display: flex;  align-items: center;
  padding: 15px 20px;  background-color: #25D366;
  overflow: hidden;}

  .sticky-chat .chat-header:after {
  content: ''; display: block; position: absolute;
  bottom: 0; right: 0;width: 80px; height: 75px;
  background: rgba(0,0,0,.040);border-radius: 70px 0 5px 0;}

  .sticky-chat .chat-header svg {
  width: 35px; height: 35px;  flex: 0 0 auto;  fill: #fff;}

  .sticky-chat .chat-header .title_wha {
  padding-left: 15px;  font-size: 14px;  font-weight: 600;
  font-family: 'Roboto',sans-serif;  color: #fff;}

  .sticky-chat .chat-header .title_wha span {
  font-size: 11px; font-weight: 400; display: block;
  line-height: 1.58em;  margin: 0;  color: #f4f4f4;}

  .sticky-chat .chat-text {
  display: flex;  flex-wrap: wrap;  margin: 30px 20px;
  font-size: 12px;}

  .sticky-chat .chat-text span {
  display: inline-block; margin-right: auto;  padding: 10px;
  background-color: #f0f5fb;  border-radius: 0px 15px 15px;}

  .sticky-chat .chat-text span:after {
  content: 'justo ahora';  display: inline-block;
  margin-left: 10px;  font-size: 9px;  color: #989b9f;}

  .sticky-chat .chat-text span.typing {
  margin: 15px 0 0 auto;  padding: 10px;
  border-radius: 15px 0px 15px 15px;}

  .sticky-chat .chat-text span.typing:after {
  display: none;}

  .sticky-chat .chat-text span.typing svg {
  height: 13px;  fill: #505050;}

  .sticky-chat .chat-button {
  display: flex;  align-items: center;  margin-top: 15px;
  padding: 12px 20px; border-radius: 10px;
  background-color: #fff;  box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
  overflow: hidden;  font-size: 12px;  font-family: 'Roboto',sans-serif;
  font-weight: 400;}

  .sticky-chat .chat-button svg {
  width: 20px;  height: 20px;  fill: #505050;  margin-left: auto;
  transform: rotate(40deg);  -webkit-transform: rotate(40deg);}

  .chat-menu:checked+.sticky-button-derma label {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);}

  .chat-menu:checked+.sticky-button-derma label svg.chat-icon {
  display: none;}

  .chat-menu:checked+.sticky-button-derma label svg.close-icon {
  display: table-cell;}

  .chat-menu:checked+.sticky-button-derma+.sticky-chat {
  bottom: 90px;  opacity: 1;  visibility: visible;}

 

/**** BTN CATALOGOS PDF ********/
.contenedor-catalogo-pdf {
    margin: 20px 0;
    padding: 20px;
    text-align: center;
}

.contenedor-catalogo-pdf a {
    position: relative;
    display: inline-block;
    height: 80px;
    width: 250px;
    line-height: 65px;
    padding: 0;
    color: #fff;
    border-radius: 50px;
    background: #fff;
    margin: 5px;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
}

.contenedor-catalogo-pdf a:hover span.circulo {
    left: 85%;
    margin-left: -35px;
    background: #618045;
}

.contenedor-catalogo-pdf a:hover span.titulo {
    opacity: 0;
}

.contenedor-catalogo-pdf a:hover span.titulo-hover {
    opacity: 1;
    color: #fff;
}

.contenedor-catalogo-pdf a span.titulo-hover {
    opacity: 0;
}

.contenedor-catalogo-pdf a span.circulo {
    display: block;
    color: #fff;
    position: absolute;
    float: left;
    margin: 3px;
    line-height: 30px;
    height: 70px;
    width: 70px;
    top: 0;
    left: 0;
    transition: all 0.5s;
    border-radius: 50%;
}

.contenedor-catalogo-pdf a span.circulo i {
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
}

.contenedor-catalogo-pdf a span.titulo, .contenedor-catalogo-pdf a span.titulo-hover {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    font-size: 16px;
    font-weight: bold;font-family: 'Montserrat', sans-serif;
    transition: .5s;
}

.contenedor-catalogo-pdf a span.titulo {
    right: 15px
}

.contenedor-catalogo-pdf a span.titulo-hover {
    left: 15px
}


/*----------Colores de los botones----------*/

.contenedor-catalogo-pdf .baikalpdf {
    border: 2px solid #618045;
}

.contenedor-catalogo-pdf .baikalpdf:hover, .contenedor-catalogo-pdf .baikalpdf span.circulo {
    background: #000;
}

.contenedor-catalogo-pdf .baikalpdf:hover span.circulo, .contenedor-catalogo-pdf .baikalpdf span.titulo {
    color: #000;
}

.contenedor-catalogo-pdf .kalashnikovpdf {
    border: 2px solid #618045;
}

.contenedor-catalogo-pdf .kalashnikovpdf:hover, .contenedor-catalogo-pdf .kalashnikovpdf span.circulo {
    background: #98b66a;
}

.contenedor-catalogo-pdf .kalashnikovpdf:hover span.circulo, .contenedor-catalogo-pdf .kalashnikovpdf span.titulo {
    color: #000;
}

.contenedor-catalogo-pdf .gplus {
    border: 2px solid #dd4b39;
}

.contenedor-catalogo-pdf .gplus:hover, .contenedor-catalogo-pdf .gplus span.circulo {
    background: #dd4b39;
}

.contenedor-catalogo-pdf .gplus:hover span.circulo, .contenedor-catalogo-pdf .gplus span.titulo {
    color: #dd4b39;
}


/*--------------Mediaqueries--------------*/

@media screen and (max-width: 480px) {
    .contenedor-catalogo-pdf a {
        width: 100%;
        margin: 0;
        margin-bottom: 10px;
    }
}




/**** MEDIA QUERY ****************/
    @media only screen and (min-width: 1023px){
       
        /****** HEADER ******/
            .nav-list{display: none !important;}
            .carmovil{display: none;}
            .header-content .hamburger,.itimovil{display: none;}
            .menufil_1{ max-width:550px;  padding: 0px 10px;margin: 0px -65px; display:inline-block !important;     }
            .menufil_2{ max-width:350px;  padding: 0px 10px;margin: 0px -75px; display:inline-block !important;     }
            .menufil_3{ max-width:350px;  padding: 0px 20px;margin: 0px -115px; display:inline-block !important;     }
            .header-nav .menu-item-has-children,
            .header-nav>li {
                width: 14%; text-align: center;     }
            .header-nav .menu-item-has-children:last-child() {   border: none    }
            .header-fixed .header-nav .menu-item-has-children {  padding: 0 2px  }
            .header-nav>li>a {      }
            .header-nav .menu-item-has-children a,
            .header-nav>li a {
                font-size: .7rem;background-repeat: no-repeat;   
                  transition: .5s; }
            .header-nav .menu-item-has-children a:hover,
            .header-nav>li a:hover {background-position: 50% 50%; background-color: #f2f2f2 ; }
            .header-nav  li .logo a:hover {background-color: inherit; }
            .header-nav .menu-item-has-children .mega-menu {
                 display: flex; position: absolute;vertical-align: middle;opacity: 0; 
                visibility: hidden;transition: all .5s ease-in-out;box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
                -webkit-transition: opacity .15s, -webkit-transform .15s;transition: opacity .15s, transform .15s;
                -webkit-transform: translateY(-10px);-moz-transform: translateY(-10px);-o-transform: translateY(-10px);
                -ms-transform: translateY(-10px);padding: 10px}
            .header-nav .menu-item-has-children:hover .mega-menu {
                opacity: 1;z-index: 999;background-color: #f2f2f2 ;
                visibility: visible;-webkit-transform: translate(0); -moz-transform: translate(0);
                -o-transform: translate(0);-ms-transform: translate(0);transform: translate(0)}
             .header-nav .menu-item-has-children .mega-menu ul {
                display: table-cell; float: left;text-align: left;padding: 0;}
            .header-nav .menu-item-has-children .mega-menu>.sub-menu {justify-content: center;flex-direction: column !important;
                display: inline-block;padding: 0;list-style: none;display: flex;} 
            .header-nav .menu-item-has-children .mega-menu>.sub-menu>ul {  text-transform: capitalize;
                min-width: 150px !important;display: flex !important;padding: 0;
                }
            .header-nav .menu-item-has-children .mega-menu>.sub-menu>a:first-child {
                font-weight: bold;font-size: .8rem; color:  rgb(37, 150, 190)  ; text-transform: uppercase;
                 text-align: left;width: 100%;padding: 10px; }
            .header-nav .menu-item-has-children .mega-menu>.sub-menu>ul>li:last-child {border: none}
            .header-nav .menu-item-has-children .mega-menu>.sub-menu>ul>li a { color: #767676;
                 font-weight: 300 }
            .header-nav .menu-item-has-children .mega-menu>.sub-menu>ul>li a:hover {color:   rgb(0, 112, 220) }
            .header-nav .menu-item-has-children .mega-menu>.sub-menu.no-child {display: block !important}
        /**** CARRUSEL *****/
        #sync2{ width: 70%;}

   .tab__item{   margin: 5px;padding: 7px;}
         /***modal subscriptores**/
           .pop-up{
            top: 75px !important;
            }   
    }
    @media only screen and (max-width: 1023px){
        /******* HEADER  ****/
            .menu_heders .sub-menu{display: none !important;}
            .js-menu{display: none !important;}
            .logo{display: none !important;}
            .carmovil,.itimovil{color: #AED6F1;text-shadow: 1px 1px 1px #fff;}
            .nav-list img{width: 90px   }
            .stikyheder{display: none !important}
            .header-content-sheart{position: absolute;left: 50%;top: 18%}
            .header-content-sheart img{         width: 90%        }
            .imglogodsk{display: none !important;}
            .header-content .hamburger,.itimovil{position: relative;left: 50px}
            /** HEADER CART **/
            .cart{   margin-top: -60px;  margin-left: 100px }
            .cart #count-item{left: -20px;top: -13px}

        /****** CARRUSEL ****/
             .testi_text_{display: flex;justify-content: center;
             align-items: center;flex-direction: column;  min-height: 350px} 
             .itemProduct ul li h2.productNames2 { min-height: 90px;}
        /******* SLIDE ******/

            .contslide{ margin-top: 40px }
        /** CHECKOUT********/
            .headercheck section  div{height: 200px; }
            .headercheck section  div h2{ font-size: 1.8rem; margin-top: 60px; }		
    }
    @media only screen and (max-width: 992px) {
        /** search navbar ***/
          .top-search-form .search-field { padding-bottom: 55%;}

        /*** SLIDER *****/
        	.contslid_text h1{font-size: 1.9rem}
            .contslid_text h4{font-size: 1rem}
    }
    @media only screen and (min-width: 768px) {
      .hdr.sticked img {width: 40% !important;}
    }
    @media only screen and (max-width: 767px) {
      .hdr.sticked img {width: 88% !important;margin-top: 5px;}
      .hdr.sticked {height: 80px; }
      .contac_noti{ padding-left: 40px;padding-right: 40px;}
      .contac_trat{padding-left:40px;padding-right: 40px;}
      .contac_trat_red{padding-left: 40px;padding-right: 40px;} 
      .contac_vid{padding-left: 40px;padding-right: 40px;}
      .contac_trat ul li{margin-left: 30px;}
      .contac_trat_red ul li{margin-left: 30px;}
      .tiendaheder section  div{ padding-top: 120px !important}
    }  
    @media only screen and (max-width: 768px) {
      .header-content-sheart img{         width: 80%        }
      /** search navbar ***/
          .top-search-form .search-field { padding-bottom: 65%;}
      /** HEADER CART **/
            .cart{   margin-top: -60px;  margin-left: 10px }
            .cart #count-item{left: -20px;top: -13px}
      /***** SLIDER **********/
            .contslide{   margin-top: 60px  }
            .contslid_text h1{font-size: 1.9rem}
            .contslid_text h4{font-size: 1rem}  
            .contslid_text_03 h1{ font-size: 1.6rem }
            .contslid_text_03 h4{  font-size: 1.5rem; }
            .contslid_text_04 h3{ font-size: 1.1rem}
			.pagination_slid li{  font-size: 5px}
        /***** CARRUSEL ********/
            .contentInner .item_published {font-size: 15px!important;line-height: 15px!important; }  
            .contentInner h3 a{ font-size: 18px} 
            .owl-carousel .owl-nav {top: 45%;} 
            .explora_productos .owl-explora-productos .owl-nav .owl-prev {  margin-left: -26px; }
            .explora_productos .owl-explora-productos .owl-nav .owl-next { margin-right: -26px; }
            .itemProduct ul li h2.productNames2 { min-height: 80px;}
        /**INFO-SOMOS-MISION ***/
            .info_img2 video{ 
                 width: 80%; text-align: center;margin: 0px auto;display: block;margin-bottom: 10px}
            .info_text  h2{ font-size: 1.4rem;margin-top: 10px }
            .somos_text  h2{font-size: 1.4rem}
            .somos_text  p{font-size: .9rem}
            .testi_ h2{font-size: 1.4rem}
            .testi_ p{font-size: .9rem}

            /*.ofer_ h2{font-size: 1.4rem}*/
        /****** TIENDA *********/
             
        /**** SHOW CART ********/

        	.show_gale .pannel_descrip_cont{ margin-top: 15px;margin-left: -10px;padding: 5px;}
    } 

    @media only screen and (max-width: 575px) {
      /** search navbar ***/
          .top-search-form .search-field { padding-bottom: 85%;font-size: 2rem}
        .header-content-sheart{position: absolute;left: -50%;top: 18%}
            .header-content-sheart img{         width: 90%        }
        /** HEADER CART **/
            .cart{   margin-top: -60px;  margin-left: 380px }
            .cart #count-item{left: -20px;top: -13px}
        .tiendaheder section{ background-image: url("../../public/img/iconos/fondo_categoria_bullet_movil.jpg") !important;
          }
    }

    @media only screen and (max-width: 500px) {

      /** search navbar ***/
      .h2shear{font-size: 1.2rem !important}
      .menu__item--hamburger{ cursor: pointer; width: 20px;    
      top: 0%; left: 90%; }
      .top-search-form .search-field { padding-bottom: 95%;font-size: 1.5rem}

      /****  HEADER ******/
      /**** HEADER CART ***/
            .cart {   left: 80%    }
            .cart img{ width:30px;      }
         /**** SLIDER *******/
            .camera_prev, .camera_next {width: 17px;  }
            .contslid_text h1{font-size: 1.1rem;padding: 0px 10px;margin-top: 50px}
            .contslid_text h4{font-size: .9rem;padding: 0px 40px;text-align: center;} 
            .contslid_text_03 h1{font-size: 1.2rem}
            .contslid_text_03 h4{ font-size: .9rem;padding: 0px 40px;}
            .contslid_text_04 h3{ font-size: .9rem;width: 100%;}
         	.contslid_text_04 h3:nth-child(1){display: none;}
            .contslid_text_04 h3:nth-child(2){ margin-top: -70px !important }
            .contslid_text_04 h3:nth-child(3){  }
            .contslid_text_04 h3:nth-child(4){display: none; }
            .contslid_text_04 h3:nth-child(5){display: none; }
        /*****CARRUSEL******/
            .owl-carousel .owl-nav {top: 40%;} 
  			#landing_section_6 h2{font-family: inherit; 
    		  text-align: center;text-shadow: 0 1px 0 #ccc, 0 1px 0 #c9c9c9, 0 3px 0 #bbb, 0 1px 0 #b9b9b9,
        	0 0px 0 #aaa,  0 0 5px rgba(0, 0, 0, 0.1),0 1px 3px rgba(0, 0, 0, 0.3),  
       		0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),0 20px 20px rgba(0, 0, 0, 0.15);
        	color: #fff; font-size: 1.9rem;font-weight: 550} 
        	.item-test{   min-height: 400px }
        	 
        	#sync2    button{font-size: .7rem !important}
        	#sync1 .item h3{font-size: .9rem        	}
        	.row_flex .row_flex_col img{	width: 90%}
          .row_flex .row_flex_col h3{ font-size: 1.5rem; }
			.banner_ofert img{ width: 90%}
			.media{		font-size: .9rem; 			}
			.media .media-middle i{	font-size: 1.9rem	}
        /****** TIENDA *****/
	        .showcard{margin-top: 30px;}
			.pannel_descrip{ border-bottom-left-radius: 20px;
			border-bottom-right-radius: 20px ;border-top-right-radius: 0px; }
	        .section-products .single-product .part-img  img{
	         border-top-right-radius: 7% ;border-top-left-radius: 7%  } 
	        .section-products .single-product .part-title .product-title {padding: 0px 1px}         
		  /***SHOW - PRODUCT**/
    		.product-count{display: inline}	
    		.contstart p{display: inline;}
    		.contstart{margin: 10px;}  
        /** CHECKOUT********/
        .headercheck section  div{height: 150px; }
        .headercheck section  div h2{ font-size: 1.4rem; margin-top: 50px; } 
        /** modal register socio**/
        .flipper {
             width: 290px;
            height: 420px;}      
    }
    @media only screen and (max-width: 435px) {
      .shear_ul li a{margin-left: 15px; font-size: 1rem}
      .top-search-form .search-field {padding-bottom: 110%;
      position: absolute; left: 0; width: 105vw;}
      /** search navbar ***/
      .menu__item--hamburger{ cursor: pointer; width: 20px;    
      top: 0%; left: 90%; }
      .top-search-form .search-field { padding-bottom: 100%;font-size: 1.5rem}
      /** HEADER CART **/
        .header-content-sheart{position: absolute;left: -30%;top: 18%}
        .header-content-sheart img{width: 60%}
        .cart{   margin-top: -60px;  margin-left: 280px }
      .tiendaheder section  div h1{ font-size: 2.4rem;}

    }

    @media only screen and (max-width: 385px){
            .shear_ul li a{margin-left: 15px; font-size: .8rem}

      .top-search-form .search-field { padding-bottom: 130% !important; }
    }

    @media only screen and (max-width: 360px){
      .h2shear{font-size: 1rem !important}

      .top-search-form .search-field {
      position: absolute;padding-bottom: 110% !important;
      left: 0; width: 105vw;}
      /** search navbar ***/
      .menu__item--hamburger{ cursor: pointer; width: 20px;    
      top: 0%; left: 90%; }
      .show-cart-header{position: absolute;top: 14px;  width: 350px;opacity: 0;   right: 5%;  
      }
    }
    @media only screen and (max-width: 340px) {
      .cart{   margin-top: -60px;  margin-left: 180px }
      .show-cart-header{  right: -40% !important;}
      .header-content-sheart{display: none !important;}
      .contslid_text_04 h3{ font-size: .7rem;width: 100%;}
      .flipper {width: 260px;height: 420px;} 
    }

 
     

/********** KEYFRAME *************/
    @keyframes hamburger_puls { 
        0% {opacity: 1; transform: scale(1);}
        100% {opacity: 0;transform: scale(1.4);}
    }

    @keyframes animate {/** efcto BTN ANIMADO**/
        0% { background-position: 0 0; }
        50% { background-position: 400% 0; }
        100% { background-position: 0 0; }
    }

    @keyframes slideRight{
        0%{ opacity: 1;transform: translateX(250px);}
        100%{opacity: 1;transform: translateX(0);}
    }
    @keyframes slideleft {
        0%{opacity: 1;  transform: translateX(-250px);}
        100%{opacity: 1;transform: translateX(0);}
    }
    @keyframes slideDown {
        0%{ opacity: 1;transform: translateY(-250px);}
        100%{opacity: 1;transform: translateY(0);}
    }
    @keyframes slideUp   {
        0%{ opacity: 1;transform: translateY(60px);}
        100%{opacity: 1;transform: translateY(0);}
    }
    @keyframes slideDowns {/**efecto aparecer*/
        0%{transform: translateY(-20px);}
        100%{ opacity: 1;transform: translateY(0);}
    }   
   
    @keyframes shadow_pulse {/** pulsebtnsocial*/
      0%{ box-shadow: 0 0 0 0px rgba(202,34,67, 1);
         transform: scale(1); }
      70%{ box-shadow: 0 0 0 15px rgba(202,34,67, 0.6);
         transform: scale(1); }
      100%{ box-shadow: 0 0 0 15px   rgba(202,34,67, 0.2);
            transform: scale(1); }
    }   

    @keyframes latido {
      0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
      }
      70% {
        -webkit-transform: scale(1.5);
        -ms-transform: scale(1.5);
        transform: scale(1.5);
      }
      100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
      }
    }



	@-webkit-keyframes zoom{           /* Google Chrome , Apple Safari */
	  0% {transform: scale(0.4);}
	  25% {transform: scale(1);}
	  50% {transform: scale(1);}
	  100% {transform: scale(0.5);}
	}
	@-moz-keyframes zoom{              /* Mozilla Firefox */
	  0% {transform: scale(0.4);}
	  25% {transform: scale(1);}
	  50% {transform: scale(1);}
	  100% {transform: scale(0.5);}
	}
	@-o-keyframes zoom{                /* *********** Opera */
	  0% {transform: scale(0.4);}
	  25% {transform: scale(1);}
	  50% {transform: scale(1);}
	  100% {transform: scale(0.5);}
	}

	@-moz-keyframes fadeInLeft{
			0% {opacity: 0;-webkit-transform: translate3d(-100%, 0, 0);transform: translate3d(-200%, 0, 0);}
			100% {opacity: 1;-webkit-transform: none;transform: none;}
	}
	@-o-keyframes fadeInLeft  {
			0% {opacity: 0;-webkit-transform: translate3d(-100%, 0, 0);transform: translate3d(-200%, 0, 0);}
			100% {opacity: 1;-webkit-transform: none;transform: none;}
	}
	@-webkit-keyframes fadeInLeft {
		0% {
				opacity: 0;
				-webkit-transform: translate3d(-100%, 0, 0);
				transform: translate3d(-200%, 0, 0);
		}
		100% {
				opacity: 1;
				-webkit-transform: none;
				transform: none;
		}
	}

	@-o-keyframes jump {
	  0% {
	    transform: scale(1, 1) translate(0px, 0px);
	  }
	  30% {
	    transform: scale(1, 0.8) translate(0px, 8px);
	  }
	  75% {
	    transform: scale(1, 1.1) translate(0px, -20px);
	  }
	  100% {
	    transform: scale(1, 1) translate(0px, 0px);
	  }
	}
	@-webkit-keyframes jump {
	  0% {transform: scale(1, 1) translate(0px, 0px);}
	  30% {transform: scale(1, 0.8) translate(0px, 8px);}
	  75% {transform: scale(1, 1.1) translate(0px, -20px);}
	  100% {transform: scale(1, 1) translate(0px, 0px);}
	}
	@-moz-keyframes jump {
	  0% {transform: scale(1, 1) translate(0px, 0px);}
	  30% {transform: scale(1, 0.8) translate(0px, 8px);}
	  75% {transform: scale(1, 1.1) translate(0px, -20px);}
	  100% {transform: scale(1, 1) translate(0px, 0px);}
	}



	/** dropsown cart header**/
  @-webkit-keyframes roule {
      from {-webkit-transform: translateX(-50%); transform: translateX(-50%);  }
      to {  -webkit-transform: translateX(0%); transform: translateX(0%);  }
  }
  @keyframes roule {
      from { -webkit-transform: translateX(-50%); transform: translateX(-50%);  }
      to {   -webkit-transform: translateX(0%);  transform: translateX(0%);  }
  }


  @-webkit-keyframes objet {
      from {  left: -10px;  }
      to {    left: 200px;  }
  }
  @keyframes objet {
      from {    left: -10px;  }
      to {    left: 200px;  }
  }


  @-webkit-keyframes caisse {
      from {    height: 0;  }
      to {    height: 22px;  }
  }
  @keyframes caisse {
      from {    height: 0;  }
      to {    height: 22px;  }
  }


  @-webkit-keyframes fadeOutTop {
      0% {    opacity: 0;  }
      50% {    opacity: 1;  }
      100% {    opacity: 0;    -webkit-transform: translateY(-100%);
        transform: translateY(-100%);  }
  }
  @keyframes fadeOutTop {
      0% { opacity: 0; }
      50% {opacity: 1; }
      100% {opacity: 0;   -webkit-transform: translateY(-100%);
        transform: translateY(-100%);}
  }


  @keyframes latido {
      0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
      }
      70% {
        -webkit-transform: scale(1.5);
        -ms-transform: scale(1.5);
        transform: scale(1.5);
      }
      100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
      }
    }
